.page-products {

    .section {
        &-products {

            .products {
                &__item {
                    position: relative;
                    z-index: 1;
                    overflow: hidden;

                    @media (max-width: $break_sm_max) {
                        margin-bottom: rem(60);
                        padding: rem(20);
                    }

                    @media (min-width: $break_sm_min) {
                        margin-bottom: rem(106);
                        padding: rem(62) rem(63) rem(66) rem(605);
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        border-radius: 50%;

                        @media (max-width: $break_sm_max) {
                            display: none;
                        }
                    }

                    &_blue {
                        .caption, .subtitle {
                            color: $color-blue-secondary;
                        }
                    }

                    &_green {
                        .caption, .subtitle {
                            color: $color-green;
                        }
                    }

                    .content {
                        position: relative;
                        z-index: 1;

                        &::before {
                            content: "";
                            background-repeat: no-repeat;
                            background-size: contain;

                            @media (max-width: $break_sm_max) {
                                display: block;
                                max-height: rem(350);
                                width: 100%;
                                margin: 0 auto;
                                background-position: center;
                            }

                            @media (min-width: $break_sm_min) {
                                position: absolute;
                                z-index: -1;
                            }
                        }

                        &::after {
                            position: absolute;
                            z-index: 1;
                            font-size: rem(15);

                            @media (max-width: $break_sm_max) {
                                top: rem(330);
                                left: 50%;
                                transform: translateX(-50%);
                                font-size: rem(10);
                                color: $color-text;
                            }

                            @media (min-width: $break_sm_min) {
                                left: rem(-629);
                                transform: rotate(-90deg);
                                font-size: rem(15);
                                color: $color-secondary;
                            }

                        }
                    }

                    &_oftolik {
                        &::before {
                            top: rem(197);
                            left: rem(-240);
                            width: rem(800);
                            height: rem(800);
                            background: linear-gradient(90deg, #2E3180 0%, #3E9BD4 95%);
                        }

                        .content {

                            &::before {
                                width: rem(435);
                                height: rem(751);
                                background-image: url("../../img/pages/products/oftolik.png");

                                @media (min-width: $break_sm_min) {
                                    top: rem(-98);
                                    left: rem(-550);
                                }
                            }

                            &::after {
                                content: "ЛСР-001359/08";

                                @media (min-width: $break_sm_min) {
                                    top: rem(348);
                                }

                            }
                        }

                        li {
                            &:nth-child(1) {
                                &::before {
                                    background-image: url("../../img/pages/products/oftolik.icon.1.svg");
                                }
                            }

                            &:nth-child(2) {
                                &::before {
                                    background-image: url("../../img/pages/products/oftolik.icon.2.svg");
                                }
                            }

                            &:nth-child(3) {
                                &::before {
                                    background-image: url("../../img/pages/products/oftolik.icon.3.svg");
                                }
                            }
                        }
                    }

                    &_oftolik-bk {
                        &::before {
                            top: rem(197);
                            left: rem(-240);
                            width: rem(800);
                            height: rem(800);
                            background: linear-gradient(90deg, #2E3180 0%, #3E9BD4 95%);
                        }

                        .content {

                            &::before {
                                width: rem(558);
                                height: rem(790);
                                background-image: url("../../img/pages/products/oftolik-bk.png");

                                @media (min-width: $break_sm_min) {
                                    top: rem(-117);
                                    left: rem(-578);
                                }
                            }

                            &::after {
                                content: "ЛСР-002804/10";

                                @media (min-width: $break_sm_min) {
                                    top: rem(348);
                                }

                            }

                            .bonus {
                                display: flex;
                                align-items: center;
                                flex-shrink: 0;
                                font-weight: 600;
                                font-style: italic;
                                font-size: rem(17);
                                line-height: rem(21);
                                letter-spacing: -0.02em;
                                color: $color-blue-secondary;

                                @media (max-width: $break_sm_max) {
                                    display: none;
                                }

                                @media (min-width: $break_sm_min) {
                                    position: absolute;
                                    z-index: 1;
                                    bottom: rem(90);
                                    left: rem(-508);
                                    width: rem(240);
                                    color: $color-secondary;
                                }
                                @media (min-width: $break_md_min) {
                                    bottom: rem(108);
                                }

                                &::before {
                                    content: "";
                                    flex-shrink: 0;
                                    width: rem(90);
                                    height: rem(90);
                                    margin-right: rem(8);
                                    background-image: url("../../img/pages/main/section.two.blue.bonus.svg");
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    border: 2px solid $color-secondary;
                                    border-radius: 50%;
                                }
                            }
                        }

                        li {
                            &:nth-child(1) {
                                &::before {
                                    background-image: url("../../img/pages/products/oftolik.icon.1.svg");
                                }
                            }

                            &:nth-child(2) {
                                &::before {
                                    background-image: url("../../img/pages/products/oftolik.bk.icon.2.svg");
                                }
                            }

                            &:nth-child(3) {
                                &::before {
                                    background-image: url("../../img/pages/products/oftolik.bk.icon.3.svg");
                                }
                            }

                            &:nth-child(4) {
                                @media (min-width: $break_sm_min) {
                                    display: none;
                                }

                                &::before {
                                    background-image: url("../../img/pages/main/section.two.blue.bonus.svg");

                                }
                            }
                        }
                    }

                    &_oftolik-vitamin {
                        &::before {
                            top: rem(67);
                            left: rem(-346);
                            width: rem(900);
                            height: rem(900);
                            background: linear-gradient(264.39deg, #D4DB4E -92.35%, #569129 89.29%);
                        }

                        .content {

                            &::before {
                                width: rem(587);
                                height: rem(587);
                                background-image: url("../../img/pages/products/oftolik.vitamin.png");

                                @media (min-width: $break_sm_min) {
                                    top: rem(23);
                                    left: rem(-603);
                                }
                            }

                            &::after {
                                content: "№RU.77.99.11.003.R.000330.02.20 от 10.02.2020 г.";

                                @media (min-width: $break_sm_min) {
                                    top: rem(345);
                                    left: rem(-752)
                                }

                            }
                        }

                        li {
                            &:nth-child(1) {
                                &::before {
                                    background-image: url("../../img/pages/products/oftolik.vitamin.icon.1.svg");
                                }
                            }

                            &:nth-child(2) {
                                &::before {
                                    background-image: url("../../img/pages/products/oftolik.vitamin.icon.2.svg");
                                }
                            }

                            &:nth-child(3) {
                                &::before {
                                    background-image: url("../../img/pages/products/oftolik.vitamin.icon.3.svg");
                                }
                            }
                        }
                    }
                }

                &__head {
                    display: flex;
                    align-items: flex-start;

                    @media (max-width: $break_sm_max) {
                        margin-top: rem(30);
                        flex-direction: column;
                    }

                    @media (min-width: $break_sm_min) {
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    .caption {
                        margin-bottom: 0;
                        font-size: rem(55);
                        line-height: rem(73);

                        @media (max-width: $break_sm_max) {
                            font-size: rem(30);
                            line-height: rem(50);
                        }
                    }

                    .subtitle {
                        margin: 0;
                        font-weight: 600;
                        font-size: rem(30);
                        line-height: rem(39);
                        text-transform: none;

                        @media (max-width: $break_sm_max) {
                            margin-bottom: rem(30);
                            font-size: rem(20);
                            line-height: rem(24);
                        }
                    }
                }

                &__description {
                    margin-top: rem(30);
                }

                &__info {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    align-content: flex-start;
                    justify-content: space-between;
                    margin-top: rem(44);
                    padding-left: 0;
                    list-style: none;

                    @media (max-width: $break_sm_max) {
                        flex-wrap: wrap;
                    }

                    li {

                        @media (max-width: $break_sm_max) {
                            width: calc(50% - 15px);
                            margin-bottom: rem(30);

                            &:last-child {

                                &::before {
                                    width: rem(87);
                                    height: rem(87);
                                    border: 3px solid $color-secondary;
                                    border-radius: 50%;
                                }
                            }
                        }

                        &::before {
                            content: "";
                            display: block;
                            width: rem(94);
                            height: rem(94);
                            margin-bottom: rem(8);
                            background-repeat: no-repeat;
                            background-size: contain;
                        }

                        p {
                            margin: 0;
                        }

                        b {
                            display: block;
                        }
                    }
                }

                &__actions {
                    position: relative;
                    display: flex;
                    margin-top: rem(75);
                    font-size: 0;

                    @media (max-width: $break_sm_max) {
                        margin-top: rem(30);
                        flex-direction: column;
                    }
                }

                &__btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: rem(71);
                    padding: rem(11) rem(30);
                    font-weight: 700;
                    font-size: rem(24);
                    line-height: 100%;

                    @media (max-width: $break_sm_max) {
                        width: 100%;
                        justify-content: center;
                        margin-bottom: rem(30);
                        padding: rem(23) rem(30);
                        font-size: rem(16);
                    }

                    &.button_white {
                        @media (min-width: $break_sm_min) {
                            margin-right: rem(55);
                        }
                    }

                    &_uteka {
                        @media (max-width: $break_xs) {
                            padding: rem(11) rem(10) rem(11) rem(100);
                        }

                        @media (max-width: $break_sm_max) {
                            padding-left: rem(100);
                        }

                        @media (min-width: $break_sm_min) {
                            position: absolute;
                            left: calc(rem(-413) + rem(-132));
                            padding-top: 0;
                            padding-bottom: 0;
                            padding-left: rem(190);
                        }
                    }
                }
            }

        }
    }
}
