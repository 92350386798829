.slick {

    &-arrow {
        position: absolute;
        top: 50%;
        z-index: 1;
        width: rem(66);
        height: rem(66);
        background-color: $color-secondary;
        background-image: url("../../img/slick-arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: $box-shadow-blocks;
        border: none;
        border-radius: 50%;
        font-size: 0;
        transform: translateY(-50%);
        transition: $transition-animate;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: rem(66);
            height: rem(66);
            background-image: url("../../img/slick-arrow.hover.svg");
            background-repeat: no-repeat;
            background-position: center;
            transition: $transition-animate;
            opacity: 0;
        }

        &:hover {
            background-color: $color-blue-secondary;

            &::after {
                opacity: 1;
            }
        }
    }

    &-disabled {
        opacity: 0.5;
        cursor: default;

        &:hover {
            background-color: $color-secondary;

            &::after {
                opacity: 0;
            }
        }
    }

    &-prev {
        left: rem(-66);

        @media (max-width: $break_sm_max) {
            right: rem(81);
            left: auto;
        }
    }
    &-next {
        right: rem(-66);
        transform: translateY(-50%) rotate(180deg);

        @media (max-width: $break_sm_max) {
            right: rem(5);
        }
    }

    &-dots {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 0;
        list-style: none;
        font-size: 0;

        li {
            margin-right: rem(11);

            &:last-child {
                margin-right: 0;
            }

            button {
                width: rem(17);
                height: rem(17);
                padding: 0;
                background-color: $color-secondary;
                border: rem(1) solid #EBEBEB;
                border-radius: 50%;
                transition: $transition-animate;
            }

            &:hover, &.slick-active {
                button {
                    background-color: $color-blue-secondary;
                    border-color: $color-blue-secondary;
                }
            }
        }
    }
}
