@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Montserrat-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/Montserrat-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Montserrat-Bold.woff') format('woff');
}
