.listing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;

    li {
        display: flex;
        color: $color-text;

        &::before {
            content: "";
            flex-shrink: 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &_icon-with-text {
        li {
            flex-direction: row;
            align-items: center;
        }
    }

    &_icon-above-text {
        li {
            flex-direction: column;
            align-items: center;
        }
    }

    &_two-col {
        justify-content: space-between;

        li {
            @media (max-width: $break_sm_max) {
                width: calc(50% - rem(30));
                margin-right: rem(30);
            }
        }
    }
}
