.accordion {
    width: 100%;
    margin: 0;

    &__action {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 3px solid $color-secondary;

        @media (max-width: $break_sm_max) {
            padding: rem(20);
        }

        @media (min-width: $break_sm_min) {
            padding: rem(20) rem(20) rem(19) rem(52);
        }

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.1;
        }

        &:nth-child(odd) {
            &::before {
                background: linear-gradient(90deg, #CDD421 0%, #7AAB35 95%);
            }
        }

        &:nth-child(even) {
            &::before {
                background: linear-gradient(264.39deg, #D4DB4E -92.35%, #569129 89.29%);
            }
        }

        &.open {
            .accordion__icon {
                transform: rotate(-90deg);

                &::before {
                    opacity: 0;
                }
            }
        }
    }

    &__caption {
        font-weight: 600;
        line-height: 122.1%;
        color: $color-text;

        @media (max-width: $break_sm_max) {
            font-size: rem(20);
        }

        @media (min-width: $break_sm_min) {
            font-size: rem(24);
        }
    }

    &__icon {
        position: relative;
        flex-shrink: 0;
        transition: $transition-animate;

        @media (max-width: $break_sm_max) {
            width: rem(20);
            height: rem(20);
        }

        @media (min-width: $break_sm_min) {
            width: rem(24);
            height: rem(24);
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            background-color: $color-green;
            border-radius: 3px;
        }

        &::before {
            top: 50%;
            transform: translateY(-50%);
            height: rem(3);
            transition: $transition-animate;

            @media (max-width: $break_sm_max) {
                width: rem(20);
            }

            @media (min-width: $break_sm_min) {
                width: rem(24);
            }
        }

        &::after {
            left: 50%;
            transform: translateX(-50%);
            width: rem(3);

            @media (max-width: $break_sm_max) {
                height: rem(20);
            }

            @media (min-width: $break_sm_min) {
                height: rem(24);
            }
        }
    }

    &__description {
        display: none;
        margin: 0;
        background: linear-gradient(360deg, #6DA734 23.72%, #95C020 150.11%);
    }

    &__par {
        margin: 0;
        font-weight: 500;
        line-height: 139.9%;
        color: $color-secondary;

        @media (max-width: $break_sm_max) {
            padding: rem(20);
            font-size: rem(18);
        }

        @media (min-width: $break_sm_min) {
            padding: rem(40) rem(73) rem(40) rem(52);
            font-size: rem(20);
        }
    }
}
