.page-oftolik-vitamin {
    .section {
        &-hero {
            @media (max-width: $break_sm_max) {
                padding-bottom: rem(30);
            }

            @media (min-width: $break_sm_min) {
                padding-bottom: rem(86);
            }

            .hero {
                &__head {
                    flex-direction: column;
                    align-items: flex-start;

                    .title {
                        margin-bottom: 0;
                        color: $color-green;
                    }

                    .subtitle {
                        margin: 0;
                        font-weight: 600;
                        color: $color-green;
                        text-transform: none;

                        @media (min-width: $break_sm_min) {
                            font-size: rem(32);
                            line-height: rem(39);
                        }
                    }
                }

                &__banner {

                    &::before {
                        width: rem(703);
                        height: rem(703);
                        background-image: url("../../img/pages/products/oftolik.vitamin.png");

                        @media (max-width: $break_xs) {
                            width: 100%;
                            max-height: rem(250);
                        }

                        @media (min-width: $break_sm_min) {
                            top: rem(-33);
                            left: rem(-136);
                        }
                    }

                    .banner__top-effects {

                        @media (min-width: $break_sm_min) {
                            overflow: hidden;
                            position: relative;
                            display: block;
                            height: 100%;
                            padding-top: rem(81);
                            padding-left: rem(582);

                            &::before {
                                content: "";
                                position: absolute;
                                z-index: -2;
                                top: rem(61);
                                left: rem(-134);
                                width: rem(667);
                                height: rem(667);
                                background: linear-gradient(264.39deg, #D4DB4E -92.35%, #569129 89.29%);
                                border-radius: 50%;
                                mix-blend-mode: normal;
                            }
                        }

                        .top-effects__item {
                            position: relative;
                            display: block;
                            color: $color-text;

                            @media (min-width: $break_sm_min) {
                                padding-bottom: rem(25);
                            }

                            &:nth-child(2),
                            &:nth-child(3) {
                                font-weight: 400;
                                color: $color-text;

                                @media (max-width: $break_sm_max) {
                                    display: flex;
                                    flex-direction: column;
                                    text-align: center;
                                }

                                @media (min-width: $break_sm_min) {
                                    padding-left: rem(116);
                                }

                                b {
                                    font-weight: 700;
                                }

                                &::before {
                                    @media (max-width: $break_sm_max) {
                                        margin-bottom: rem(12);
                                    }

                                    @media (min-width: $break_sm_min) {
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        transform: translateY(-55%);
                                    }
                                }
                            }


                            &:nth-child(1) {
                                @media (min-width: $break_sm_min) {
                                    margin-bottom: rem(33);
                                }

                                .caption {
                                    margin-top: 0;
                                    margin-bottom: rem(18);
                                    color: $color-text;

                                    @media (max-width: $break_xs) {
                                        font-size: rem(18);
                                    }

                                    @media (max-width: $break_sm_max) {
                                        text-align: center;
                                    }

                                }

                                .bonus-17 {
                                    @media (max-width: $break_sm_max) {
                                        flex-direction: column;
                                    }

                                    &::after {
                                        left: 0;
                                        font-style: normal;

                                        @media (max-width: $break_sm_max) {
                                            width: 100%;
                                            text-align: center;
                                        }
                                    }

                                    &::before {
                                        background: linear-gradient(264.39deg, #D4DB4E -92.35%, #569129 89.29%);

                                        @media (max-width: $break_sm_max) {
                                            width: rem(70);
                                            height: rem(70);
                                            margin-right: 0;
                                            margin-bottom: rem(12);
                                        }
                                    }
                                }

                                &::before {
                                    display: none;
                                }
                            }

                            &:nth-child(2) {
                                @media (min-width: $break_sm_min) {
                                    margin-bottom: rem(29);
                                }

                                &::before {
                                    background-image: url("../../img/pages/oftolik-vitamin/hero.icon.1.svg");
                                }
                            }

                            &:nth-child(3) {

                                &::before {
                                    background-image: url("../../img/pages/oftolik-vitamin/hero.icon.2.svg");
                                }
                            }
                        }
                    }
                }
            }
        }

        &-compound {

            .content {
                display: flex;

                @media (max-width: $break_sm_max) {
                    flex-direction: column;
                }

                @media (min-width: $break_sm_min) {
                    flex-direction: row;
                    justify-content: space-between;
                }
            }

            .compound {
                &__head {
                    max-width: rem(822);
                    background: linear-gradient(90deg, #2E3180 0%, #3E9BD4 95%);
                    border-radius: 100px;
                    text-align: center;

                    @media (max-width: $break_sm_max) {
                        margin: rem(60) auto;
                        padding-top: rem(16);
                        padding-bottom: rem(16);
                    }

                    @media (min-width: $break_sm_min) {
                        margin: rem(70) auto rem(81);
                    }

                    .caption {
                        margin: 0;
                        color: $color-secondary;
                        text-transform: none;

                        @media (max-width: $break_sm_max) {
                            line-height: normal;
                        }

                        @media (min-width: $break_sm_min) {
                            line-height: rem(74);
                        }
                    }
                }

                &__block {
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $break_sm_max) {
                        margin-bottom: rem(60);
                    }

                    @media (min-width: $break_sm_min) {
                        position: relative;
                        width: calc((100% / 3) - rem(24));

                        &::before {
                            content: "";
                            position: absolute;
                            z-index: 2;
                            width: rem(78);
                            height: rem(95);
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-image: url("../../img/pages/oftolik-vitamin/compound.arrow.svg");
                        }
                    }

                    .par {
                        margin: 0;
                        font-weight: 700;

                        i {
                            font-weight: 400;
                        }
                    }

                    &:nth-child(1) {
                        &::before {
                            top: rem(-88);
                            right: rem(40);
                        }

                        .block__top {
                            &::before {
                                background-image: url("../../img/pages/oftolik-vitamin/compound.icon.1.svg");
                            }
                        }
                    }

                    &:nth-child(2) {
                        &::before {
                            top: rem(-83);
                            left: 50%;
                            height: rem(75);
                            transform: translateX(-50%) matrix(-0.77, -0.66, -0.62, 0.77, 0, 0);
                        }

                        .block__top {
                            &::before {
                                background-image: url("../../img/pages/oftolik-vitamin/compound.icon.2.svg");
                            }
                        }
                    }

                    &:nth-child(3) {
                        &::before {
                            top: rem(-88);
                            left: rem(23);
                            transform: matrix(-1, 0, 0, 1, 0, 0);
                        }

                        .block__top {
                            &::before {
                                background-image: url("../../img/pages/oftolik-vitamin/compound.icon.3.svg");
                            }
                        }
                    }

                    .block {
                        &__top {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            background: linear-gradient(264.39deg, #D4DB4E -92.35%, #569129 89.29%);
                            border-radius: 20px 20px 0px 0px;

                            @media (max-width: $break_sm_max) {
                                padding: rem(10) rem(20);
                            }

                            @media (min-width: $break_sm_min) {
                                padding: rem(12) rem(21);
                            }

                            &::before {
                                content: "";
                                flex-shrink: 0;
                                margin-right: rem(8);
                                background-repeat: no-repeat;
                                background-size: contain;

                                @media (max-width: $break_sm_max) {
                                    width: rem(70);
                                    height: rem(70);
                                }

                                @media (min-width: $break_sm_min) {
                                    width: rem(103);
                                    height: rem(103);
                                }
                            }

                            .par {
                                color: $color-secondary;
                            }
                        }

                        &__content {
                            height: 100%;
                            padding: rem(24) rem(37) rem(46);
                            background-color: #EDF8FF;
                            border-radius: 0px 0px 20px 20px;

                            @media (max-width: $break_sm_max) {
                                padding: rem(30) rem(20);
                            }

                            @media (min-width: $break_sm_min) {
                                padding: rem(24) rem(37) rem(46);
                            }

                        }

                        &__listing {
                            padding-left: rem(16);
                            font-size: rem(16);
                        }
                    }
                }
            }
        }

        &-details {
            @media (max-width: $break_sm_max) {
                margin-top: rem(40);
                margin-bottom: rem(80);
            }

            @media (min-width: $break_sm_min) {
                margin-top: rem(117);
                margin-bottom: rem(117);
            }

            .title {
                @media (min-width: $break_sm_min) {
                    margin-bottom: rem(45);
                    font-size: rem(48);
                    line-height: rem(59);
                }
            }

            .content {
                display: flex;

                @media (max-width: $break_sm_max) {
                    flex-direction: column;
                }

                @media (min-width: $break_sm_min) {
                    flex-direction: row;
                    justify-content: space-between;
                }

                .col {
                    max-width: rem(680);
                    flex: 1 1 auto;
                }
            }
        }
    }
}
