.page-symptoms {

    .section {
        &-vision-syndrome {

            .title {
                margin-bottom: rem(74);

                @media (max-width: $break_sm_max) {
                    margin-bottom: rem(20);
                }
            }

            .block-first {
                padding-top: rem(77);
                padding-bottom: rem(125);

                @media (max-width: $break_sm_max) {
                    padding-top: rem(60);
                    padding-bottom: rem(60);
                }

                .content {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    max-width: rem(1413);
                    margin-right: auto;
                    margin-left: auto;

                    @media (max-width: $break_sm_max) {
                        flex-direction: column;
                    }
                }

                &__eye {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: rem(465);
                    height: rem(478);
                    background-image: url("../../img/pages/symptoms/vision.syndrome.block.first.bg.png");
                    background-repeat: no-repeat;
                    background-size: contain;

                    @media (max-width: $break_xs) {
                        height: rem(300);
                    }

                    @media (max-width: $break_sm_max) {
                        position: relative;
                        top: auto;
                        left: auto;
                        transform: translate(0,0) rotate(90deg);
                        height: rem(400);
                        margin-top: rem(120);
                        margin-bottom: rem(116);
                        background-position: center;
                    }

                    &::before, &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: rem(59);
                        height: rem(21);
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    &::before {
                        left: rem(-80);
                        background-image: url("../../img/pages/symptoms/vision.syndrome.block.first.drop.blue.svg");

                        @media (max-width: $break_xs) {
                            left: rem(0);
                        }

                        @media (max-width: $break_sm_max) {
                            left: rem(-40);
                        }
                    }

                    &::after {
                        right: rem(-76);
                        background-image: url("../../img/pages/symptoms/vision.syndrome.block.first.drop.green.svg");

                        @media (max-width: $break_xs) {
                            right: rem(0);
                        }

                        @media (max-width: $break_sm_max) {
                            right: rem(-40);
                        }
                    }
                }

                &__item {
                    display: flex;
                    flex-direction: column;
                    font-size: 0;

                    @media (max-width: $break_sm_max) {
                        text-align: center;
                    }

                    @media (min-width: $break_sm_min) {
                        margin-top: rem(8);
                    }

                    .caption {
                        margin-bottom: rem(12);

                        @media (max-width: $break_sm_max) {
                            text-align: center;
                        }

                        &::before {
                            content: "";
                            display: block;
                            width: rem(116);
                            height: rem(116);
                            margin-bottom: rem(18);
                            background-repeat: no-repeat;
                            background-size: contain;

                            @media (max-width: $break_sm_max) {
                                margin-right: auto;
                                margin-left: auto;
                            }
                        }
                    }
                    .par {
                        margin: 0;
                        font-size: rem(24);
                        line-height: rem(29);

                        @media (max-width: $break_sm_max) {
                            font-size: rem(18);
                            line-height: rem(20);
                            text-align: center;
                        }
                    }

                    .button {
                        opacity: 0;
                        max-width: rem(300);
                        margin-top: rem(30);
                        font-weight: 600;
                        font-size: rem(23);

                        @media (max-width: $break_md_max) {
                            opacity: 1;
                            font-size: rem(20);
                        }
                    }

                    &:hover {
                        .button {
                            opacity: 1;
                        }
                    }

                    &_blue {
                        .caption {
                            color: $color-blue-secondary;

                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.first.blue.svg");
                            }
                        }
                    }

                    &_green {
                        .caption {
                            color: $color-green;

                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.first.green.svg");
                            }
                        }
                    }
                }

            }

            .block-second {
                padding: rem(30) rem(16) 0;

                @media (min-width: $break_sm_min) {
                    height: rem(732);
                    padding: rem(40) rem(36);
                }

                .caption {
                    @media (min-width: $break_sm_min) {
                        margin-bottom: 0;
                    }
                }

                &__symptoms {
                    position: relative;
                    max-width: rem(1200);
                    height: 100%;
                    margin: 0 auto;
                    padding-left: 0;
                    list-style: none;

                    @media (max-width: $break_sm_max) {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }

                    @media (min-width: $break_sm_min) {
                        &::before {
                            content: "";
                            position: absolute;
                            top: rem(112);
                            left: rem(414);
                            width: rem(393);
                            height: rem(393);
                            background-image: url("../../img/pages/symptoms/vision.syndrome.block.second.bg.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }

                    .symptoms__item {
                        display: flex;
                        font-size: rem(24);
                        line-height: rem(29);
                        color: $color-text;

                        @media (max-width: $break_sm_max) {
                            flex-direction: column;
                            align-items: center;
                            width: 45%;
                            margin-bottom: rem(40);
                            font-size: rem(18);
                            line-height: rem(24);
                            text-align: center;

                            &::before {
                                content: "";
                                width: rem(83);
                                height: rem(83);
                                margin-bottom: rem(16);
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }

                        @media (min-width: $break_sm_min) {
                            position: absolute;
                            flex-direction: row;

                            &:nth-child(-n+4) {
                                &::after {
                                    content: "";
                                    width: rem(83);
                                    height: rem(83);
                                    margin-left: rem(16);
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                }
                            }
                            &:nth-child(1n+5) {
                                &::before {
                                    content: "";
                                    width: rem(83);
                                    height: rem(83);
                                    margin-right: rem(16);
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                }
                            }
                        }

                        &:nth-child(1) {

                            @media (min-width: $break_sm_min) {
                                top: rem(65);
                                left: rem(157);
                                align-items: flex-start;
                            }

                            &::before, &::after {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.second.1.svg");

                                @media (min-width: $break_sm_min) {
                                    margin-top: rem(13);
                                }
                            }
                        }

                        &:nth-child(2) {

                            @media (min-width: $break_sm_min) {
                                top: rem(183);
                                left: rem(157);
                                align-items: center;
                            }

                            &::before, &::after {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.second.2.svg");
                            }
                        }

                        &:nth-child(3) {

                            @media (min-width: $break_sm_min) {
                                bottom: rem(229);
                                left: rem(105);
                                align-items: center;
                            }

                            &::before, &::after {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.second.3.svg");
                            }
                        }

                        &:nth-child(4) {

                            @media (min-width: $break_sm_min) {
                                bottom: rem(97);
                                left: rem(323);
                                align-items: flex-end;
                            }

                            &::before, &::after {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.second.4.svg");

                                @media (min-width: $break_sm_min) {
                                    margin-bottom: rem(19);
                                }
                            }
                        }

                        &:nth-child(5) {

                            @media (min-width: $break_sm_min) {
                                top: rem(65);
                                right: rem(270);
                                align-items: flex-start;
                            }

                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.second.5.svg");

                                @media (min-width: $break_sm_min) {
                                    margin-top: rem(13);
                                }
                            }
                        }

                        &:nth-child(6) {

                            @media (min-width: $break_sm_min) {
                                top: rem(183);
                                right: rem(-31);
                                align-items: center;
                            }

                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.second.6.svg");
                            }
                        }

                        &:nth-child(7) {

                            @media (min-width: $break_sm_min) {
                                bottom: rem(229);
                                right: rem(120);
                                align-items: center;
                            }

                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.second.7.svg");
                            }
                        }

                        &:nth-child(8) {

                            @media (min-width: $break_sm_min) {
                                bottom: rem(97);
                                right: rem(166);
                                align-items: flex-end;
                            }

                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.second.8.svg");

                                @media (min-width: $break_sm_min) {
                                    margin-bottom: rem(19);
                                }
                            }
                        }
                    }
                }
            }

            .block-third {
                padding-top: rem(133);
                padding-bottom: rem(144);

                @media (max-width: $break_sm_max) {
                    padding-top: rem(60);
                    padding-bottom: rem(60);
                }

                .caption {
                    margin-bottom: rem(40);
                }

                &__repercussion {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-left: 0;
                    list-style: none;

                    @media (max-width: $break_sm_max) {
                        flex-direction: column;
                        align-items: center;
                    }

                    .repercussion__item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 50%;
                        margin-bottom: rem(33);
                        font-size: rem(24);
                        line-height: rem(29);
                        color: $color-text;

                        @media (max-width: $break_sm_max) {
                            width: 100%;
                            margin-bottom: rem(40);
                            font-size: rem(18);
                            line-height: rem(24);

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        &::before {
                            content: "";
                            flex-shrink: 0;
                            width: rem(116);
                            height: rem(116);
                            margin-right: rem(33);
                            background-repeat: no-repeat;
                            background-size: contain;

                            @media (max-width: $break_sm_max) {
                                width: rem(80);
                                height: rem(80);
                                margin-right: rem(16);
                            }
                        }

                        &:nth-child(1) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.third.1.svg");
                            }
                        }

                        &:nth-child(2) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.third.2.svg");
                            }
                        }

                        &:nth-child(3) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.third.3.svg");
                            }
                        }

                        &:nth-child(4) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vision.syndrome.block.third.4.svg");
                            }
                        }
                    }
                }
            }

        }

        &-prophylaxy {
            padding-bottom: rem(158);

            @media (max-width: $break_sm_max) {
                padding-bottom: rem(40);
            }

            .title {
                font-size: rem(48);
                line-height: rem(59);

                @media (max-width: $break_sm_max) {
                    margin-bottom: rem(20);
                    font-size: rem(30);
                    line-height: rem(36);
                }
            }

            .subtitle {
                font-size: rem(32);
                line-height: rem(39);
                text-transform: none;

                @media (max-width: $break_sm_max) {
                    margin-bottom: rem(40);
                    font-size: rem(22);
                    line-height: rem(24);
                }

                @media (min-width: $break_sm_min) {
                    margin-bottom: rem(80);
                }
            }
        }
    }

    .article {
        &-vmd {
            .title {
                &_article {
                    margin-bottom: rem(70);
                    font-weight: 400;
                    font-size: rem(38);
                    line-height: rem(46);
                    color: $color-text;

                    @media (max-width: $break_sm_max) {
                        margin-bottom: rem(40);
                        font-size: rem(24);
                        line-height: rem(30);
                    }
                }
            }

            .caption {
                color: $color-green;
            }

            .blockquote {
                b {
                    text-transform: none;
                }
            }

            .block-first {
                margin-top: rem(69);
                padding: rem(59) rem(66);

                @media (max-width: $break_sm_max) {
                    padding: rem(30) rem(16) 0;
                }

                .symptoms {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-shrink: 0;
                    margin-top: rem(67);
                    padding-left: 0;
                    list-style: none;

                    @media (max-width: $break_sm_max) {
                        flex-direction: column;
                        align-items: center;
                    }

                    &__item {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: rem(24);
                        line-height: rem(29);
                        text-align: center;

                        @media (max-width: $break_sm_max) {
                            width: rem(280);
                            margin-bottom: rem(40);
                            font-size: rem(20);
                            line-height: rem(26);
                        }

                        @media (min-width: $break_sm_min) {
                            width: rem(320);
                        }

                        &::before {
                            content: "";
                            width: rem(181);
                            height: rem(181);
                            margin-bottom: rem(33);
                            background-repeat: no-repeat;
                            background-size: contain;

                            @media (max-width: $break_sm_max) {
                                width: rem(141);
                                height: rem(141);
                                margin-right: auto;
                                margin-left: auto;
                            }
                        }

                        &:after {
                            position: absolute;
                            z-index: 1;
                            top: rem(-2);
                            left: rem(69);
                            width: rem(53);
                            height: rem(53);
                            background: linear-gradient(90deg, #CDD421 0%, #7AAB35 95%);
                            border-radius: 50%;
                            font-weight: 700;
                            font-size: rem(30);
                            line-height: rem(53);
                            color: $color-secondary;
                            text-align: center;

                            @media (max-width: $break_sm_max) {
                                left: rem(80);
                                width: rem(33);
                                height: rem(33);
                                font-size: rem(20);
                                line-height: rem(33);
                            }
                        }

                        &:nth-child(1) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vmd.symptoms.item.1.svg");
                            }

                            &::after {
                                content: "1";
                            }
                        }

                        &:nth-child(2) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vmd.symptoms.item.2.svg");
                            }

                            &::after {
                                content: "2";
                            }
                        }

                        &:nth-child(3) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vmd.symptoms.item.3.svg");
                            }

                            &::after {
                                content: "3";
                            }
                        }

                        &:nth-child(4) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vmd.symptoms.item.4.svg");
                            }

                            &::after {
                                content: "4";
                            }
                        }

                    }
                }
            }

            .block-second {
                padding-top: rem(133);
                padding-bottom: rem(144);

                @media (max-width: $break_sm_max) {
                    padding-top: rem(60);
                    padding-bottom: rem(60);
                }

                .caption {
                    margin-bottom: rem(40);
                }

                .risk-factors {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-left: 0;
                    list-style: none;

                    @media (max-width: $break_sm_max) {
                        flex-direction: column;
                        align-items: center;
                    }

                    &__item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 50%;
                        margin-bottom: rem(40);
                        font-size: rem(24);
                        line-height: rem(29);
                        color: $color-text;

                        @media (max-width: $break_sm_max) {
                            width: 100%;
                            font-size: rem(18);
                            line-height: rem(24);

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        @media (min-width: $break_sm_min) {
                            padding-right: rem(80);
                        }

                        &::before {
                            content: "";
                            flex-shrink: 0;
                            width: rem(118);
                            height: rem(118);
                            margin-right: rem(33);
                            background-repeat: no-repeat;
                            background-size: contain;

                            @media (max-width: $break_sm_max) {
                                width: rem(80);
                                height: rem(80);
                                margin-right: rem(16);
                            }
                        }

                        &:nth-child(1) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vmd.risk-factors.item.1.svg");
                            }
                        }

                        &:nth-child(2) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vmd.risk-factors.item.2.svg");
                            }
                        }

                        &:nth-child(3) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vmd.risk-factors.item.3.svg");
                            }
                        }

                        &:nth-child(4) {
                            &::before {
                                background-image: url("../../img/pages/symptoms/vmd.risk-factors.item.4.svg");
                            }
                        }
                    }
                }
            }

            .block-third {
                margin-bottom: rem(48);

                .flyer {

                    .caption {
                        color: $color-secondary;
                    }

                    &-description {
                        max-width: rem(577);

                        .caption {
                            @media (max-width: $break_sm_max) {
                                margin-bottom: rem(20);
                            }
                        }
                    }
                }
            }

        }
    }
}
