.button {
    padding: rem(18) rem(30);
    border-radius: rem(1000);
    border: none;
    border: 2px solid transparent;
    color: $color-secondary;
    text-decoration: none;
    text-align: center;
    transition: $transition-animate;
    cursor: pointer;

    font-size: rem(16);

    &_green {
        background-color: $color-green;
        border-color: $color-green;

        &:hover {
            background-color: $color-secondary;
            color: $color-green;
        }
    }

    &_blue {
        background-color: $color-blue-secondary;
        border-color: $color-blue-secondary;

        &:hover {
            background-color: $color-secondary;
            color: $color-blue-secondary;
        }
    }

    &_white {
        background-color: $color-secondary;
        color: $color-text;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        &:hover {
            background-color: $color-text;
            color: $color-secondary;
        }

        &_blue {
            border-color: $color-blue-secondary;
            color: $color-blue-secondary;

            &:hover {
                background-color: $color-blue-secondary;
            }
        }

        &_green {
            border-color: $color-green;
            color: $color-green;

            &:hover {
                background-color: $color-green;
            }
        }

    }

    &_gradient {
        border-color: $color-secondary;

        &_blue {
            background: linear-gradient(90deg, #2E3180 0%, #3E9BD4 95%);

            &:hover {
                background: linear-gradient(264.39deg, #D4DB4E -92.35%, #569129 89.29%);
            }
        }

        &_green {
            background: linear-gradient(264.39deg, #D4DB4E -92.35%, #569129 89.29%);

            &:hover {
                background: linear-gradient(90deg, #2E3180 0%, #3E9BD4 95%);
            }
        }
    }

    &_text {
        padding: 0;
        background: transparent;
        border: none;
        color: $color-blue-secondary;
        text-decoration: underline;

        &:hover {
            color: $color-text;
        }
    }

    &_uteka {
        position: relative;
        z-index: 1;
        padding-left: rem(190);
        font-weight: 700;

        @media (max-width: $break_xs) {
            padding: rem(11) rem(10) rem(11) rem(100);
            font-size: rem(16);
        }

        @media (max-width: $break_sm_max) {
            padding-left: rem(100);
        }

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 50%;
            left: rem(20);
            width: rem(126);
            height: rem(44);
            background-image: url("../../img/pages/vision-care/btn.icon.uteka.png");
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateY(-50%);

            @media (max-width: $break_xs) {
                width: rem(63);
                height: rem(22);
            }
        }

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            top: rem(-1);
            left: rem(-1);
            width: rem(157);
            height: calc(100% + 0.1rem);
            background-color: $color-secondary;
            border-radius: 1000px 0px 0px 1000px;

            @media (max-width: $break_xs) {
                width: rem(90);
            }
        }
    }

    &_switch {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        background: #CDE7F9;
        box-shadow: 0px 10px 30px rgba(89, 71, 38, 0.08);
        color: $color-blue-secondary;
        text-transform: uppercase;
        outline: none;

        @media (max-width: $break_xs) {
            padding-top: rem(11);
            padding-bottom: rem(11);
        }

        @media (max-width: $break_sm_max) {
            justify-content: space-around;
            width: 100%;
        }

        .text {
            height: 100%;
            font-weight: 700;
            font-size: rem(20);
            line-height: rem(37);

            @media (max-width: $break_xs) {
                font-size: rem(16);
            }

            @media (min-width: $break_sm_min) {
                padding-right: rem(30);
                padding-left: rem(30);
                font-size: rem(20);
            }

            &::before {
                content: "";
                position: absolute;
                top: rem(6);
                width: rem(62);
                height: calc(100% - rem(12));
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        &[aria-checked="true"] {
            :first-child {
                color: $color-secondary;

                @media (max-width: $break_sm_max) {
                    padding-left: rem(25);
                }

                @media (min-width: $break_sm_min) {
                    padding-left: rem(56);
                }

               &::before {
                   left: rem(6);
                   background-image: url("../../img/rus.png");
               }
            }

            .checked {
                width: 50%;
                left: 0;
            }
        }

        &[aria-checked="false"] {
            :last-child {
                color: $color-secondary;

                @media (max-width: $break_xs) {
                    padding-right: rem(20);
                }

                @media (max-width: $break_sm_max) {
                    padding-right: rem(25);
                }

                @media (min-width: $break_sm_min) {
                    padding-right: rem(56);
                }

                &::before {
                    right: rem(6);
                    background-image: url("../../img/rus.png");
                    background-position: right;
                }
            }

            .checked {
                left: 40%;
                width: 60%;
            }
        }

        .checked {
            position: absolute;
            z-index: -1;
            height: 100%;
            top: 0;
            background: linear-gradient(90deg, #2E3180 0%, #3E9BD4 95%);
            border-radius: rem(1000);
            transition: $transition-animate;
        }
    }
}
