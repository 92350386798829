.header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(103);
    background-color: $color-blue-main;

    @media (max-width: $break_sm_max) {
        height: rem(50);
    }

    .layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        @media (max-width: $break_md_max) {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                z-index: 2;
                top: 0;
                left: -$fields;
                width: calc(100% + ($fields * 2));
                height: 100%;
                background-color: $color-blue-main;
            }
        }
    }

    .brand {
        @include clearfix;

        flex-shrink: 0;
        margin-right: rem(30);
        height: rem(46);
        font-size: 0;

        @media (max-width: $break_md_max) {
            position: relative;
            z-index: 2;
        }

        @media (min-width: $break_md_min) {
            margin-top: rem(-12);
        }

        @media (min-width: $break_lg) {
            margin-right: rem(50);
        }

        img {
            width: rem(235);
            height: rem(46);

            @media (max-width: $break_sm_max) {
                width: 140px;
                height: 100%;
            }
        }
    }

    .nav {
        display: flex;
        align-items: center;
        height: 100%;

        @media (max-width: $break_md_max) {
            display: block;
            position: fixed;
            z-index: -1;
            visibility: hidden;
            top: -100%;
            left: 0;
            width: 100%;
            height: auto;
            margin: 0;
            background-color: $color-blue-main;
            list-style: none;
            text-align: center;
            transition: $transition-animate;

            &.show {
                z-index: 1;
                visibility: visible;
                top: -100%;

                @media (max-width: $break_md_max) {
                    top: rem(103);
                }

                @media (max-width: $break_sm_max) {
                    top: rem(50);
                }
            }
        }

        &__list {
            display: flex;
            align-items: center;
            list-style: none;

            @media (max-width: $break_md_max) {
                flex-direction: column;
                margin: rem(10) 0;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            margin-left: rem(17);

            @media (max-width: $break_md_max) {
                margin: rem(10) 0;
            }

            &::after {
                content: "";
                width: rem(5);
                height: rem(5);
                margin-left: rem(10);
                background: $color-secondary;
                border-radius: 50%;

                @media (max-width: $break_md_max) {
                    display: none;
                }

                @media (min-width: $break_xxl) {
                    margin-left: rem(17);
                }
            }

            &:last-child {
                @media (min-width: $break_md_min) {
                    margin-right: rem(47);
                }

                &::after {
                    display: none;
                }
            }
        }

        &__btn {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            position: relative;
            width: rem(250);
            height: 100%;
            border: none;
            background-color: #6fa835;
            text-align: center;

            @media (max-width: $break_md_max) {
                width: 100%;
                height: auto;
                padding: rem(10) 0;
            }

            &::before {
                content: "";
                display: block;
                width: rem(45);
                height: rem(45);
                margin-right: rem(10);
                background-image: url("../../../img/header-buy.svg");
                background-size: contain;
                background-repeat: no-repeat;

                @media (min-width: $break_md_min) {
                    width: rem(55);
                    height: rem(55);
                }
                @media (min-width: $break_lg) {
                    width: rem(67);
                    height: rem(67);
                }
            }

            &::after {
                content: "";
                position: absolute;
                z-index: -1;
                right: 0;
                bottom: rem(-22);
                width: 100%;
                height: rem(23);
                background-color: #6fa835;
                border-radius: 0 0 23px 23px;

                @media (max-width: $break_md_max) {
                    display: none;
                }
            }
        }

        &__hamburger {
            display: none;

            @media (max-width: $break_md_max) {
                display: flex;
                align-items: center;
                position: relative;
                z-index: 2;
                width: rem(26);
                height: rem(26);
                cursor: pointer;
                font-size: 0;
                transition: $transition-animate;
            }

            span {
                display: block;
                position: relative;
                width: 100%;
                height: rem(2);
                background-color: $color-secondary;

                &::before, &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: rem(2);
                    background-color: $color-secondary;
                }

                &::before {
                    top: rem(-8);
                }

                &::after {
                    top: rem(8);
                }
            }

            &.active {
                span {
                    transform: rotate(45deg);

                    &::before {
                        top: 0;
                        transform: rotate(0);
                    }

                    &::after {
                        top: 0;
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }

    .link {
        font-size: rem(18);
        line-height: rem(22);
        letter-spacing: 0.015em;
        text-align: center;
        white-space: nowrap;
        transition: $transition-animate;

        @media (max-width: $break_md_max) {
            font-size: rem(12);
            line-height: rem(16);
        }
    }
}
