.section-literature {
    .literature {
        &__action {
            padding-top: rem(35);
            padding-bottom: rem(40);
            background-color: #EDF8FF;

            .content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .caption {
                margin: 0;
                font-size: rem(18);
                line-height: rem(20);
                color: $color-text;
            }
        }

        &__btn_text {
            font-weight: 700;
            font-size: rem(18);
            line-height: rem(20);
        }

        &__accordion {
            display: none;
            padding-top: rem(35);
            padding-bottom: rem(40);
            background-color: rgba(237, 248, 255, 0.5);

            ol {
                font-size: 0;
                padding-left: $fields;

                li {
                    font-size: rem(15);
                    line-height: rem(25);
                }
            }
        }
    }
}
