.page-vision-care {
    .section {
        &-about-products {
            position: relative;
            z-index: 1;

            .title {
                margin-bottom: rem(74);

                @media (max-width: $break_sm_max) {
                    margin-bottom: rem(20);
                }
            }

            .blockquote {
                &_blue {
                    position: relative;
                    z-index: 2;
                    margin-top: rem(56);
                }

                &_green {
                    margin-top: rem(141);

                    @media (max-width: $break_sm_max) {
                        margin-top: rem(50);
                    }
                }

            }

            &__drops {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-top: rem(70);
                padding-bottom: rem(246);

                @media (max-width: $break_sm_max) {
                    align-items: center;
                    padding-top: rem(40);
                    padding-bottom: rem(80);
                }

                .content {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    @media (max-width: $break_sm_max) {
                        align-items: center;
                    }

                    @media (min-width: $break_sm_min) {
                        left: rem(-50);
                    }

                    @media (min-width: $break_md_min) {
                        left: rem(-100);
                    }

                    @media (min-width: $break_lg) {
                        left: auto;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: rem(-137);
                        left: rem(-1170);
                        width: rem(1230);
                        height: rem(963);
                        background-image: url("../../img/pages/vision-care/drops.bg.png");
                        background-repeat: no-repeat;
                        background-size: contain;

                        @media (max-width: $break_xs) {
                            max-height: rem(250);
                        }

                        @media (max-width: $break_sm_max) {
                            position: relative;
                            top: auto;
                            left: auto;
                            width: 100%;
                            max-height: rem(350);
                            background-position: center;
                        }

                    }
                }

                .drops {

                    &__item {
                        position: relative;

                        @media (max-width: $break_sm_max) {
                            max-width: rem(565);
                            width: 100%;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            background-repeat: no-repeat;
                            background-size: contain;

                            @media (max-width: $break_sm_max) {
                                display: none;
                            }
                        }

                        @media (max-width: $break_sm_max) {
                            left: auto;
                            top: auto;
                        }

                        &_up {
                            left: rem(-53);
                            top: rem(7);
                            max-width: rem(340);
                            margin-bottom: rem(70);

                            @media (max-width: $break_sm_max) {
                                margin-bottom: rem(40);
                            }

                            &::before {
                                top: rem(15);
                                left: rem(-268);
                                width: rem(238);
                                height: rem(144);
                                background-image: url("../../img/pages/vision-care/drops.arrow.up.svg");
                            }

                            .caption {
                                &::before {
                                    width: rem(77);
                                    height: rem(79);
                                }
                            }
                        }

                        &_down {
                            left: rem(22);
                            top: rem(2);
                            max-width: rem(565);

                            &::before {
                                bottom: rem(-143);
                                left: rem(-164);
                                width: rem(236);
                                height: rem(127);
                                background-image: url("../../img/pages/vision-care/drops.arrow.down.svg");
                            }

                            .caption {
                                &::before {
                                    width: rem(88);
                                    height: rem(91);
                                }
                            }
                        }
                    }
                }

                .caption {
                    margin-bottom: rem(12);
                    color: $color-blue-main;
                    text-transform: none;

                    @media (max-width: $break_sm_max) {
                        margin-bottom: rem(12);
                        font-size: rem(22);
                        line-height: rem(24);
                        text-align: center;
                    }

                    &::before {
                        content: "";
                        display: block;
                        margin-left: rem(5);
                        margin-bottom: rem(12);
                        background-image: url("../../img/pages/vision-care/drops.icon.svg");
                        background-repeat: no-repeat;
                        background-size: contain;

                        @media (max-width: $break_sm_max) {
                            margin-right: auto;
                            margin-left: auto;
                        }

                        @media (max-width: $break_xs) {
                            display: none;
                        }
                    }
                }

                p {
                    margin: 0;
                }
            }

            &__vitamin {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                height: rem(793);

                @media (max-width: $break_sm_max) {
                    align-items: center;
                    height: auto;
                    padding-bottom: rem(40);
                }

                .content {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    @media (max-width: $break_sm_max) {
                        align-items: center;
                    }

                    @media (min-width: $break_sm_min) {
                        left: rem(-50);
                    }

                    @media (min-width: $break_md_min) {
                        left: rem(-100);
                    }

                    @media (min-width: $break_lg) {
                        left: auto;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: rem(-3);
                        left: rem(-995);
                        width: rem(1059);
                        height: rem(857);
                        background-image: url("../../img/pages/vision-care/vitamin.bg.png");
                        background-repeat: no-repeat;
                        background-size: contain;

                        @media (max-width: $break_xs) {
                            max-height: rem(250);
                            width: rem(250);
                        }

                        @media (max-width: $break_sm_max) {
                            position: relative;
                            top: auto;
                            left: auto;
                            max-height: rem(350);
                            width: rem(350);
                            background-position: center;
                        }

                        @media (max-width: $break_lg) {
                            left: rem(-1066);
                        }

                    }
                }

                .vitamin {

                    &__item {
                        position: relative;
                        top: rem(452);
                        left: rem(41);
                        width: rem(500);

                        @media (max-width: $break_sm_max) {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            top: auto;
                            left: auto;
                            max-width: rem(565);
                            width: 100%;

                        }

                        &::before {
                            content: "";
                            position: absolute;
                            top: rem(80);
                            left: rem(-284);
                            width: rem(271);
                            height: rem(187);
                            background-image: url("../../img/pages/vision-care/vitamin.arrow.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            opacity: 0.8;

                            @media (max-width: $break_sm_max) {
                                display: none;
                            }
                        }
                    }
                }

                .caption {
                    margin-bottom: rem(16);
                    line-height: 105%;
                    color: $color-text;
                    text-transform: none;

                    @media (max-width: $break_sm_max) {
                        margin-bottom: rem(16);
                        text-align: center;
                    }
                }
            }

            .flyer {
                z-index: 1;
                margin-bottom: rem(100);

                @media (max-width: $break_sm_max) {
                    margin-bottom: rem(50);
                }

                &_blue {
                    .layout {
                        &::before {
                            height: rem(472);

                            @media (min-width: $break_sm_min) {
                                top: rem(-107);
                                left: rem(-176);
                                width: rem(605);
                            }
                        }
                    }
                }

                &_green {
                    .layout {
                        &::before {
                            height: rem(489);

                            @media (min-width: $break_sm_min) {
                                top: rem(-101);
                                left: rem(-70);
                                width: rem(489);
                            }
                        }
                    }
                }
            }
        }

        &-article {

            .content {
                max-width: rem(1200);
            }

            .caption {
                margin-top: rem(80);
                line-height: 105%;
                color: $color-text;

                @media (max-width: $break_sm_max) {
                    margin-top: rem(40);
                    font-size: rem(26);
                }

                &_green {
                    color: $color-green;
                }
            }

            .smart-vitamins {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                max-width: rem(1160);
                height: rem(660);
                margin: rem(50) auto;

                @media (max-width: $break_xs) {
                    flex-direction: column;
                }

                @media (max-width: $break_sm_max) {
                    align-items: flex-start;
                    max-width: 100%;
                    height: auto;
                }

                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: rem(-22);
                    left: 51%;
                    width: rem(307);
                    height: rem(660);
                    background-image: url("../../img/pages/vision-care/smart-vitamins.bg.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    transform: translateX(-50%);

                    @media (max-width: $break_sm_max) {
                        display: none;
                    }
                }

                .col {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    @media (max-width: $break_sm_max) {
                        align-items: center;
                        width: 100%;
                    }
                }

                &__item {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    max-width: rem(220);

                    @media (max-width: $break_sm_max) {
                        position: relative;
                        top: auto;
                        right: auto;
                        bottom: auto;
                        left: auto;
                        min-height: rem(180);
                        margin-bottom: rem(20);
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        background-repeat: no-repeat;
                        background-size: contain;

                        @media (max-width: $break_sm_max) {
                            display: none;
                        }
                    }
                }

                &__icon {
                    &-up {
                        &-left {
                            left: rem(160);
                            top: rem(82);

                            &::before {
                                right: rem(-142);
                                top: rem(-52);
                                width: rem(194);
                                height: rem(62);
                                background-image: url("../../img/pages/vision-care/smart-vitamins.arrow.up.left.svg");
                            }

                            p {
                                &::before {
                                    height: rem(57);
                                    background-image: url("../../img/pages/vision-care/smart-vitamins.icon.up.left.svg");

                                    @media (max-width: $break_sm_max) {
                                        height: rem(100);
                                    }
                                }
                            }
                        }
                        &-right {
                            right: rem(30);
                            top: rem(162);

                            &::before {
                                left: rem(-218);
                                top: rem(-82);
                                width: rem(245);
                                height: rem(123);
                                background-image: url("../../img/pages/vision-care/smart-vitamins.arrow.up.right.svg");
                            }

                            p {
                                &::before {
                                    height: rem(58);
                                    background-image: url("../../img/pages/vision-care/smart-vitamins.icon.up.right.svg");

                                    @media (max-width: $break_sm_max) {
                                        height: rem(100);
                                    }
                                }
                            }
                        }
                    }

                    &-down {
                        &-left {
                            left: rem(11);
                            bottom: rem(153);

                            &::before {
                                left: rem(188);
                                top: rem(-56);
                                width: rem(246);
                                height: rem(149);
                                background-image: url("../../img/pages/vision-care/smart-vitamins.arrow.down.left.svg");
                            }

                            p {
                                &::before {
                                    height: rem(89);
                                    background-image: url("../../img/pages/vision-care/smart-vitamins.icon.down.left.svg");

                                    @media (max-width: $break_sm_max) {
                                        height: rem(100);
                                    }
                                }
                            }
                        }
                        &-right {
                            right: rem(129);
                            bottom: rem(121);

                            &::before {
                                left: rem(-105);
                                top: rem(-79);
                                width: rem(194);
                                height: rem(62);
                                background-image: url("../../img/pages/vision-care/smart-vitamins.arrow.down.right.svg");
                            }

                            p {
                                &::before {
                                    height: rem(67);
                                    background-image: url("../../img/pages/vision-care/smart-vitamins.icon.down.right.svg");

                                    @media (max-width: $break_sm_max) {
                                        height: rem(100);
                                    }
                                }
                            }
                        }
                    }
                }

                &__name {
                    margin: 0;
                    font-weight: 600;
                    font-style: italic;
                    font-size: rem(32);
                    line-height: 105%;
                    color: $color-green;
                    text-align: center;

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        margin-bottom: rem(13);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;

                        @media (max-width: $break_sm_max) {
                            background-size: auto;
                        }
                    }

                    @media (max-width: $break_sm_max) {
                        font-size: rem(22);
                        line-height: rem(24);
                    }
                }
            }
        }
    }
}
