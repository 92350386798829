 .breadcrumb {
     padding-top: rem(38);
     padding-bottom: rem(126);
     padding-left: rem(20);

     @media (max-width: $break_sm_max) {
         padding-top: rem(30);
         padding-bottom: rem(30);
         padding-left: 0;
     }

     &__list {
         display: flex;
         flex-direction: row;
         list-style: none;
         margin: 0;
         padding-left: 0;
     }

     &__item {
         font-size: rem(15);
         line-height: rem(34);
         color: #95B6E2;

         @media (max-width: $break_sm_max) {
             font-size: rem(12);
             line-height: rem(16);
         }

         &::after {
             content: "/";
             margin: 0 rem(5);
         }

         &:last-child {
             &::after {
                 display: none;
             }
         }

         a {
             color: $color-blue-secondary;
             text-decoration: none;
         }

         &_active {

         }
     }
 }
