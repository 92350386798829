.hero {

    &__head {
        display: flex;

        @media (max-width: $break_sm_max) {
            flex-direction: column;
            margin-bottom: rem(20);
        }

        @media (min-width: $break_sm_min) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: rem(85);
        }

        .title {
            margin-bottom: 0;
            line-height: 100%;

            @media (max-width: $break_sm_max) {
                margin-bottom: rem(20);
                line-height: rem(36);
            }
        }
    }

    &__banner {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: 1;


        @media (max-width: $break_sm_max) {
            flex-direction: column;
            padding: 0 rem(20) rem(40);
        }

        @media (min-width: $break_sm_min) {
            height: rem(587);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        &::before {
            content: "";
            background-repeat: no-repeat;
            background-size: contain;

            @media (max-width: $break_sm_max) {
                display: block;
                max-height: rem(350);
                width: 100%;
                margin: 0 auto;
                background-position: center;
            }

            @media (min-width: $break_sm_min) {
                position: absolute;
                z-index: -1;
            }
        }

        .banner__top-effects {
            display: flex;
            flex-direction: column;
            list-style: none;

            @media (min-width: $break_sm_min) {
                padding-left: rem(685);
            }

            .top-effects__item {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-weight: 700;
                font-size: rem(24);
                line-height: rem(29);
                color: $color-secondary;

                @media (max-width: $break_sm_max) {
                    width: 100%;
                    padding: 0;
                    margin-bottom: rem(40);
                    font-size: rem(16);
                    line-height: rem(22);
                    color: $color-blue-secondary;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &::before {
                    content: "";
                    flex-shrink: 0;
                    width: rem(103);
                    height: rem(103);
                    margin-right: rem(15);
                    background-repeat: no-repeat;
                    background-size: contain;

                    @media (max-width: $break_sm_max) {
                        width: rem(70);
                        height: rem(70);
                    }
                }
            }
        }
    }

    &__actions {
        position: relative;
        display: flex;
        font-size: 0;

        @media (max-width: $break_sm_max) {
            margin-top: rem(30);
            flex-direction: column;
        }

        @media (min-width: $break_sm_min) {
            justify-content: flex-end;
            margin-top: rem(60);
            margin-right: rem(144);

            > :first-child {
                margin-right: rem(60);
            }
        }

    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(11) rem(30);
        font-weight: 700;
        font-size: rem(24);
        line-height: 100%;

        @media (max-width: $break_sm_max) {
            width: 100%;
            justify-content: center;
            height: rem(75);
            margin-bottom: rem(30);
            padding: rem(23) rem(30);
            font-size: rem(16);
        }

        &_uteka {
            filter: drop-shadow(0px 10px 30px rgba(89, 71, 38, 0.08));

            @media (max-width: $break_xs) {
                padding: rem(11) rem(10) rem(11) rem(100);
            }

            @media (max-width: $break_sm_max) {
                padding-left: rem(100);
            }

            @media (min-width: $break_sm_min) {
                padding-left: rem(190);
            }
        }
    }
}
