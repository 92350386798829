.section-reviews {
    background-color: #F5F5F5;

    @media (max-width: $break_xs) {
        padding-bottom: rem(120);
    }

    @media (max-width: $break_md_max) {
        padding-top: rem(80);
        padding-bottom: rem(80);
    }

    @media (min-width: $break_md_min) {
        padding-top: rem(113);
        padding-bottom: rem(139);
    }

    .title {
        text-transform: none;

        @media (max-width: $break_sm_max) {
            margin-bottom: rem(60);
        }
    }

    .reviews {
        &__slider {
            display: flex;

            @media (min-width: $break_sm_min) {
                width: calc(100% - 7.5rem);
                margin: 0 auto;
            }

            .slick-track {
                display: flex;
                padding-bottom: rem(30);
            }

            .slick-arrow {
                @media (max-width: $break_xs) {
                    top: auto;
                    bottom: rem(-100);

                    &.slick-prev {
                        right: auto;
                    }
                }

                @media (max-width: $break_sm_max) {
                    top: rem(-78);
                }
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            height: auto;
            margin: 0 rem(21);
            background-color: $color-secondary;

            @media (max-width: $break_sm_max) {
                padding: rem(25) rem(15);
            }

            @media (min-width: $break_sm_min) {
                padding: rem(48) rem(60) rem(60);
            }
        }
        &__user {
            position: relative;
            padding-left: rem(119);
            font-size: 0;

            @media (max-width: $break_sm_max) {
                padding-left: rem(86);
            }

            @media (min-width: $break_sm_min) {
                padding-left: rem(119);
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                width: rem(103);
                height: rem(103);
                background-image: url("../../img/user.mock.svg");
                background-size: contain;
                border-radius: 50%;

                @media (max-width: $break_sm_max) {
                    top: 50%;
                    transform: translateY(-50%);
                    width: rem(70);
                    height: rem(70);
                }

                @media (min-width: $break_sm_min) {
                    top: 0;
                    width: rem(103);
                    height: rem(103);
                }
            }

            .user {
                &__name {
                    margin-bottom: rem(10);
                    font-weight: 700;
                    color: $color-blue-main;

                    @media (max-width: $break_sm_max) {
                        font-size: rem(20);
                        line-height: rem(25);
                    }

                    @media (min-width: $break_sm_min) {
                        font-size: rem(24);
                        line-height: rem(29);
                    }
                }
                &__date {

                    @media (max-width: $break_sm_max) {
                        font-size: rem(16);
                        line-height: rem(20);
                    }

                    @media (min-width: $break_sm_min) {
                        font-size: rem(20);
                        line-height: rem(24);
                    }
                }
            }

            .rating {
                margin-top: rem(10);
                li {
                    width: rem(22);
                    height: rem(22);
                    margin-right: rem(4);
                }
            }
        }
        &__blockquote {
            position: relative;
            display: inline-block;
            margin-top: rem(43);

            @media (max-width: $break_sm_max) {
                margin: rem(20) 0 0;
                font-size: rem(18);
                line-height: rem(20);
            }

            @media (min-width: $break_sm_min) {
                font-size: rem(24);
                line-height: rem(29);
            }

            &::before, &::after {
                content: "";
                position: absolute;
                width: rem(39);
                height: rem(29);
                background-repeat: no-repeat;
                background-size: contain;

                @media (max-width: $break_sm_max) {
                    display: none;
                }
            }

            &::before {
                top: rem(-8);
                left: rem(-45);
                background-image: url("../../img/blockquote.before.svg");
            }

            &::after {
                bottom: rem(-8);
                margin-left: rem(10);
                background-image: url("../../img/blockquote.after.svg");
            }
        }
    }
}
