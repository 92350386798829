.reviews {
    display: flex;
    align-items: center;

    @media (max-width: $break_sm_max) {
        align-items: flex-start;
    }

    &_row {
        flex-direction: row;

        @media (max-width: $break_sm_max) {
            flex-direction: column;
        }

        .rating {
            @media (min-width: $break_sm_min) {
                margin-right: rem(20);
            }

            @media (max-width: $break_sm_max) {
                margin-bottom: rem(7);
            }
        }
    }

    &_col {
        flex-direction: column;

        @media (max-width: $break_sm_max) {
            align-items: flex-start;
        }

        .rating {
            margin-bottom: rem(7);
        }
    }

    span {
        font-weight: 600;
        font-size: rem(18);
        line-height: rem(22);
        color: $color-text;
        white-space: nowrap;

        @media (max-width: $break_sm_max) {
            font-size: rem(16);
            line-height: rem(20);
        }

        a {
            color: $color-text;
        }
    }
}

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    list-style: none;

    li {
        width: rem(38);
        height: rem(38);
        margin-right: rem(8);
        background-image: url("../../img/rating.star.svg");
        background-repeat: no-repeat;
        background-size: contain;

        @media (max-width: $break_sm_max) {
            width: rem(24);
            height: rem(24);
            margin-right: rem(5);
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
