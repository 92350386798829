.page-oftolik {

    .section {
        &-hero {
            @media (max-width: $break_sm_max) {
                padding-bottom: rem(60);
            }

            @media (min-width: $break_sm_min) {
                padding-bottom: rem(86);
            }

            .hero {
                &__banner {
                    @media (min-width: $break_sm_min) {
                        background-image: url("../../img/pages/oftolik/hero.bg.png");
                    }

                    &::before {
                        width: rem(396);
                        height: rem(683);
                        background-image: url("../../img/pages/products/oftolik.png");

                        @media (max-width: $break_xs) {
                            width: 100%;
                        }

                        @media (min-width: $break_sm_min) {
                            top: rem(124);
                            left: rem(-85);
                        }
                    }

                    .banner__top-effects {
                        .top-effects__item {
                            @media (min-width: $break_sm_min) {
                                padding-top: rem(25);
                                padding-bottom: rem(25);
                            }

                            &:nth-child(1) {
                                &::before {
                                    background-image: url("../../img/pages/oftolik/hero.icon.1.svg");
                                }
                            }

                            &:nth-child(2) {

                                @media (min-width: $break_sm_min) {
                                    padding-left: rem(34);
                                }

                                &::before {
                                    background-image: url("../../img/pages/oftolik/hero.icon.2.svg");
                                }
                            }

                            &:nth-child(3) {
                                &::before {
                                    background-image: url("../../img/pages/oftolik/hero.icon.3.svg");
                                }
                            }
                        }
                    }
                }
            }
        }

        &-advantages {

            @media (max-width: $break_sm_max) {
                padding-bottom: rem(60);
            }

            @media (min-width: $break_sm_min) {
                padding-top: rem(120);
                padding-bottom: rem(60);
            }

            @media (min-width: $break_md_min) {
                padding-bottom: rem(152);
            }

            .adv__list {
                justify-content: space-between;

                li {
                    font-weight: 600;
                    font-size: rem(24);
                    line-height: rem(29);
                    color: $color-text;

                    @media (max-width: $break_sm_max) {
                        flex-direction: column;
                        width: calc((100% / 2) - rem(30));
                        margin-bottom: rem(40);
                        font-size: rem(16);
                        line-height: rem(22);
                        text-align: center;
                    }

                    @media (min-width: $break_sm_min) {
                        width: calc((100% / 3) - rem(60));
                        margin-right: rem(60);
                        margin-bottom: rem(100);
                    }

                    &::before {

                        @media (max-width: $break_sm_max) {
                            width: rem(80);
                            height: rem(80);
                            margin-bottom: rem(13);
                        }

                        @media (min-width: $break_sm_min) {
                            width: rem(94);
                            height: rem(94);
                            margin-right: rem(13);
                        }
                    }

                    &:nth-child(1) {
                        &:before {
                            background-image: url("../../img/pages/oftolik/adv.icon.1.svg");
                        }
                    }

                    &:nth-child(2) {
                        &:before {
                            background-image: url("../../img/pages/oftolik/adv.icon.2.svg");
                        }
                    }

                    &:nth-child(3) {
                        &:before {
                            background-image: url("../../img/pages/oftolik/adv.icon.3.svg");
                        }
                    }

                    &:nth-child(4) {
                        &:before {
                            background-image: url("../../img/pages/oftolik/adv.icon.4.svg");
                        }
                    }

                    &:nth-child(5) {
                        &:before {
                            background-image: url("../../img/pages/oftolik/adv.icon.5.svg");
                        }
                    }

                    &:nth-child(6) {
                        &:before {
                            background-image: url("../../img/pages/oftolik/adv.icon.6.svg");
                        }
                    }
                }
            }
        }

        &-components {
            .components {

                &__banner {
                    overflow: hidden;

                    .content {
                        position: relative;
                        z-index: 2;
                        width: 100%;

                        @media (max-width: $break_md_max) {
                            display: flex;
                            flex-direction: column-reverse;
                            padding: 0 rem(20) rem(20);
                        }

                        @media (min-width: $break_md_min) {
                            max-width: rem(510);
                            padding: rem(60) rem(50) rem(50) 0;
                        }
                    }
                }

                &__item {
                    margin-bottom: rem(19);

                    .caption {
                        margin-bottom: 0;
                        text-transform: none;
                    }

                    .par {
                        @media (min-width: $break_md_min) {
                            margin: 0;
                            font-size: rem(20);
                        }
                    }
                }

                &__action {

                    @media (max-width: $break_md_max) {
                        display: flex;
                        justify-content: center;
                        margin-top: rem(60);
                        margin-bottom: rem(60);
                    }

                    @media (min-width: $break_md_min) {
                        margin-top: rem(36);
                    }
                }

                &__btn {
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding: rem(6);
                    padding-right: rem(34);
                    font-weight: 700;
                    font-size: rem(24);
                    line-height: rem(29);
                    text-align: left;
                    cursor: pointer;

                    @media (max-width: $break_sm_max) {
                        font-size: rem(20);
                        line-height: rem(25);
                    }

                    @media (min-width: $break_sm_min) {
                        font-size: rem(24);
                        line-height: rem(29);
                    }

                    &::before {
                        content: "";
                        width: rem(61);
                        height: rem(61);
                        flex-shrink: 0;
                        margin-right: rem(11);
                        background-color: $color-secondary;
                        background-image: url("../../img/play.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 50% 50%;
                        border-radius: 50%;
                        font-size: 0;
                        transition: $transition-animate;

                        @media (max-width: $break_md_max) {
                            width: rem(41);
                            height: rem(41);
                        }
                    }
                }
            }
        }

        &-instruction {
            @media (max-width: $break_md_max) {
                padding-top: rem(80);
                padding-bottom: rem(80);
            }

            @media (min-width: $break_md_min) {
                padding-top: rem(160);
                padding-bottom: rem(160);
            }

            .content {
                display: flex;

                @media (max-width: $break_sm_max) {
                    flex-direction: column;
                }

                @media (min-width: $break_sm_min) {
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-end;
                }
            }

            .instruction{
                &__text {

                    @media (min-width: $break_sm_min) {
                        max-width: rem(504);
                        margin-right: rem(90);
                    }

                    .caption {
                        @media (min-width: $break_sm_min) {
                            margin-bottom: rem(71);
                            max-width: rem(504);
                        }
                    }
                    .par {
                        b {
                            display: block;
                        }
                    }
                }

                &__steps {
                    display: flex;
                    align-items: flex-start;

                    @media (max-width: $break_sm_max) {
                        flex-direction: column;
                        align-items: center;
                        margin-top: rem(100);
                    }

                    @media (min-width: $break_sm_min) {
                        flex-direction: row;
                    }

                    .steps {
                        &__item {
                            position: relative;
                            flex-shrink: 0;
                            padding-left: rem(148);

                            @media (max-width: $break_xs) {
                                margin-bottom: rem(160);
                            }

                            @media (max-width: $break_sm_max) {
                                margin-bottom: rem(220);
                            }

                            @media (min-width: $break_sm_min) {
                                margin-right: rem(80);
                            }

                            &:last-child {
                                @media (max-width: $break_xs) {
                                    margin-bottom: rem(80);
                                }

                                @media (max-width: $break_sm_max) {
                                    margin-bottom: rem(120);
                                }

                                @media (min-width: $break_sm_min) {
                                    margin-right: rem(80);
                                }
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                top: rem(-79);
                                left: 0;
                                width: rem(126);
                                height: rem(255);
                                background-repeat: no-repeat;
                                background-size: contain;
                            }

                            &:nth-child(1) {
                                &::before {
                                    background-image: url("../../img/step.1.svg");
                                }
                            }

                            &:nth-child(2) {
                                &::before {
                                    background-image: url("../../img/step.2.svg");
                                }
                            }
                        }
                        &__num {
                            margin: 0;
                            color: $color-blue-secondary;
                        }
                        &__descr {
                            margin: 0;
                            font-weight: 700;
                        }
                    }

                }
            }
        }
        &-reviews {
            background-color: #F5F5F5;

            @media (max-width: $break_xs) {
                padding-bottom: rem(120);
            }

            @media (max-width: $break_md_max) {
                padding-top: rem(80);
                padding-bottom: rem(80);
            }

            @media (min-width: $break_md_min) {
                padding-top: rem(113);
                padding-bottom: rem(139);
            }

            .title {
                text-transform: none;

                @media (max-width: $break_sm_max) {
                    margin-bottom: rem(60);
                }
            }

            .reviews {
                &__slider {
                    display: flex;

                    @media (min-width: $break_sm_min) {
                        width: calc(100% - 7.5rem);
                        margin: 0 auto;
                    }

                    .slick-track {
                        display: flex;
                        padding-bottom: rem(30);
                    }

                    .slick-arrow {
                        @media (max-width: $break_xs) {
                            top: auto;
                            bottom: rem(-100);

                            &.slick-prev {
                                right: auto;
                            }
                        }

                        @media (max-width: $break_sm_max) {
                            top: rem(-78);
                        }
                    }
                }
                &__item {
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    margin: 0 rem(21);
                    background-color: $color-secondary;

                    @media (max-width: $break_sm_max) {
                        padding: rem(25) rem(15);
                    }

                    @media (min-width: $break_sm_min) {
                        padding: rem(48) rem(60) rem(60);
                    }
                }
                &__user {
                    position: relative;
                    padding-left: rem(119);
                    font-size: 0;

                    @media (max-width: $break_sm_max) {
                        padding-left: rem(86);
                    }

                    @media (min-width: $break_sm_min) {
                        padding-left: rem(119);
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        width: rem(103);
                        height: rem(103);
                        background-image: url("../../img/user.mock.svg");
                        background-size: contain;
                        border-radius: 50%;

                        @media (max-width: $break_sm_max) {
                            top: 50%;
                            transform: translateY(-50%);
                            width: rem(70);
                            height: rem(70);
                        }

                        @media (min-width: $break_sm_min) {
                            top: 0;
                            width: rem(103);
                            height: rem(103);
                        }
                    }

                    .user {
                        &__name {
                            margin-bottom: rem(10);
                            font-weight: 700;
                            color: $color-blue-main;

                            @media (max-width: $break_sm_max) {
                                font-size: rem(20);
                                line-height: rem(25);
                            }

                            @media (min-width: $break_sm_min) {
                                font-size: rem(24);
                                line-height: rem(29);
                            }
                        }
                        &__date {

                            @media (max-width: $break_sm_max) {
                                font-size: rem(16);
                                line-height: rem(20);
                            }

                            @media (min-width: $break_sm_min) {
                                font-size: rem(20);
                                line-height: rem(24);
                            }
                        }
                    }

                    .rating {
                        margin-top: rem(10);
                        li {
                            width: rem(22);
                            height: rem(22);
                            margin-right: rem(4);
                        }
                    }
                }
                &__blockquote {
                    position: relative;
                    display: inline-block;
                    margin-top: rem(43);

                    @media (max-width: $break_sm_max) {
                        margin: rem(20) 0 0;
                        font-size: rem(18);
                        line-height: rem(20);
                    }

                    @media (min-width: $break_sm_min) {
                        font-size: rem(24);
                        line-height: rem(29);
                    }

                    &::before, &::after {
                        content: "";
                        position: absolute;
                        width: rem(39);
                        height: rem(29);
                        background-repeat: no-repeat;
                        background-size: contain;

                        @media (max-width: $break_sm_max) {
                            display: none;
                        }
                    }

                    &::before {
                        top: rem(-8);
                        left: rem(-45);
                        background-image: url("../../img/blockquote.before.svg");
                    }

                    &::after {
                        bottom: rem(-8);
                        margin-left: rem(10);
                        background-image: url("../../img/blockquote.after.svg");
                    }
                }
            }
        }

        &-article-prev {

            @media (max-width: $break_md_max) {
                padding-top: rem(80);
                padding-bottom: rem(80);
            }

            @media (min-width: $break_md_min) {
                padding-top: rem(120);
                padding-bottom: rem(150);
            }

            .content {
                display: flex;

                @media (max-width: $break_sm_max) {
                    flex-direction: column;
                }

                @media (min-width: $break_sm_min) {
                    flex-direction: row;
                    justify-content: space-between;
                }
            }

            .article-prev {
                &__item {
                    max-width: rem(471);
                    padding: rem(38) rem(18) 0;

                    @media (min-width: $break_sm_min) {
                        width: rem(471);
                    }

                    a {
                        text-decoration: none;
                    }

                    &_product {
                        background-image: url("../../img/artcile.bg.jpg");
                        background-repeat: no-repeat;
                        background-size: contain;

                        .caption {
                            color: $color-secondary;
                            text-transform: uppercase;
                        }

                        .article-prev__tag {
                            color: rgba(255, 255, 255, 0.7);
                        }
                    }
                }

                &__content {
                    padding: 0 rem(21) rem(20);
                    font-size: 0;

                    .caption {
                        margin-top: rem(10);
                        margin-bottom: rem(10);
                        font-size: rem(26);
                        line-height: 105.1%;
                        text-transform: none;
                    }
                    .par {
                        margin: 0;
                        font-weight: 500;
                        font-size: rem(18);
                        line-height: rem(18);
                        color: $color-text;
                    }
                }

                &__tag {
                    font-size: rem(16);
                    line-height: rem(20);
                    color: rgba(41, 82, 174, 0.7);
                    text-decoration: underline;
                }
            }
        }
    }
}
