.flyer {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    height: rem(414);
    padding: rem(80) rem(70);
    box-shadow: 0px 10px 30px rgba(89, 71, 38, 0.08);
    border-radius: rem(51);
    color: $color-secondary;

    @media (max-width: $break_sm_max) {
        height: auto;
        padding: rem(30) rem(20);
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        border-radius: 50%;
    }

    .layout {
        position: relative;
        max-width: rem(1298);
        padding-left: rem(408);

        @media (max-width: $break_sm_max) {
            padding-left: 0;
        }

        &::before {
            content: "";
            position: absolute;
            background-repeat: no-repeat;
            background-size: contain;

            @media (max-width: $break_sm_max) {
                position: relative;
                display: block;
                margin: 0 auto;
                width: 100%;
                background-position: center;
            }
        }
    }

    .caption {
        color: $color-secondary;

        @media (max-width: $break_sm_max) {
            margin-bottom: rem(15);
        }
    }

    .par {
        margin: 0;
        font-size: rem(30);
        line-height: rem(36);

        @media (max-width: $break_sm_max) {
            font-size: rem(18);
            line-height: rem(24);
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        margin-top: rem(54);

        @media (max-width: $break_sm_max) {
            flex-direction: column;
        }
    }

    &__btn {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        padding: rem(11) rem(30);
        font-weight: 700;
        font-size: rem(24);
        line-height: 100%;
        text-align: center;

        &:first-child {
            margin-right: rem(60);

            @media (max-width: $break_sm_max) {
                width: 100%;
                min-height: 74px;
                margin-right: 0;
                margin-bottom: rem(30);
            }
        }

        @media (max-width: $break_sm_max) {
            justify-content: center;
            padding: rem(23) rem(30);
            font-size: rem(16);
        }

        &_uteka {
            padding-left: rem(190);

            @media (max-width: $break_xs) {
                padding: rem(11) rem(10) rem(11) rem(100);
                font-size: rem(16);
            }

            @media (max-width: $break_sm_max) {
                padding-left: rem(100);
            }
        }
    }

    &_blue {
        background: linear-gradient(220.13deg, #0093C9 3.49%, #2C4A9D 100.95%);

        &::before,
        &::after {
            z-index: -1;
            width: rem(432);
            height: rem(432);
            background-color: $color-blue-secondary;
            border-radius: 50%;
            mix-blend-mode: normal;
            opacity: 0.6;

        }

        &::before {
            top: rem(-155);
            right: rem(-145);
        }

        &::after {
            bottom: rem(-125);
            left: rem(-115);
        }

        .layout {
            &::before {
                background-image: url("../../img/flyer.drops.png");

                @media (max-width: $break_xs) {
                    max-height: rem(250);
                }
                @media (max-width: $break_sm_max) {
                    max-height: rem(400);
                }
            }
        }

        .round {
            position: absolute;
            z-index: -1;
            width: rem(146);
            height: rem(146);
            background-color: $color-blue-secondary;
            border-radius: 50%;
            mix-blend-mode: normal;
            opacity: 0.6;

            &_left {
                top: rem(-29);
                left: rem(148);
            }

            &_right {
                bottom: rem(-29);
                right: rem(129);
            }

            @media (max-width: $break_sm_max) {
                display: none;
            }
        }
    }

    &_green {
        background: linear-gradient(199.03deg, #7CB62C 22.59%, #619E31 81.86%, #619E31 91.78%);

        &::before,
        &::after {
            background-color: #559716;
        }

        &::before {
            top: rem(-90);
            right: rem(-70);
            width: rem(275);
            height: rem(275);
        }

        &::after {
            bottom: rem(-170);
            left: rem(-145);
            width: rem(525);
            height: rem(525);
        }

        .layout {
            &::before {
                background-image: url("../../img/flyer.vitamin.png");

                @media (max-width: $break_xs) {
                    max-height: rem(250);
                    width: rem(250);
                }

                @media (max-width: $break_sm_max) {
                    max-height: rem(400);
                }
            }
        }
    }

    &_mini {
        display: flex;
        align-items: flex-end;
        max-width: rem(678);
        padding: rem(51) rem(10) rem(61);

        @media (max-width: $break_sm_max) {
            margin-bottom: rem(40);
            padding: rem(30) rem(15);
        }

        @media (min-width: $break_sm_min) {
            padding: rem(51) rem(10) rem(61);
        }


        .layout {
            display: flex;
            flex-direction: column;
            padding-left: rem(350);
        }

        .caption {
            font-size: rem(28);
            line-height: rem(34);
            text-transform: none;

            @media (max-width: $break_sm_max) {
                font-size: rem(20);
                line-height: rem(22);
            }
        }

        .actions {
            margin-top: rem(10);
        }

        .flyer__btn {
            justify-content: center;
            min-width: rem(260);
            margin-right: 0;
            padding: 0;
            font-weight: 600;
            font-size: rem(23);
            line-height: rem(64);
            letter-spacing: 0.03em;
            text-transform: uppercase;

            @media (max-width: $break_sm_max) {
                margin-bottom: 0;
                font-size: rem(18);
                line-height: rem(50);
            }
        }

        &.flyer_blue {
            &::before {
                display: none;
            }

            .layout {
                &::before {
                    height: rem(454);

                    @media (min-width: $break_sm_min) {
                        top: rem(-71);
                        left: rem(-163);
                        width: rem(581);
                    }
                }
            }
        }

        &.flyer_green {
            @media (max-width: $break_sm_max) {
                &::before {
                    display: none;
                }
            }

            .layout {
                &::before {
                    height: rem(379);

                    @media (min-width: $break_sm_min) {
                        top: rem(-41);
                        left: rem(-10);
                        width: rem(379);
                    }
                }
            }
        }
    }

    &-description {
        display: flex;
        flex-direction: column;

        .par {
            margin: 0;
            font-size: rem(32);
            line-height: rem(38);

            @media (max-width: $break_sm_max) {
                font-size: rem(22);
                line-height: rem(24);
            }

            b {
                display: block;
            }

            span {
                text-transform: uppercase;
            }
        }
    }
}

.block-flyers {
    .content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: rem(1440);

        @media (max-width: $break_sm_max) {
            flex-direction: column;
        }

        @media (min-width: $break_sm_min) {
            flex-direction: row;
        }

        @media (min-width: $break_xxl) {
            justify-content: space-between;
        }

        >:first-child {
            @media (max-width: $break_sm_max) {
                margin-bottom: rem(40);
            }

            @media (min-width: $break_sm_min) {
                margin-right: rem(60);
            }
        }
    }
}
