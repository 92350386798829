html {

    @media (max-width: $break_sm_max) {
        font-size: 16px;
    }

    @media (min-width: $break_sm_min) {
        font-size: 8px;
    }

    @media (min-width: $break_md_min) {
        font-size: 10px;
    }

    @media (min-width: $break_lg) {
        font-size: 12px;
    }

    @media (min-width: $break_xl) {
        font-size: 14px;
    }

    @media (min-width: $break_xxl) {
        font-size: 16px;
    }
}

body {
    min-height: 100%;
    min-width: 320px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16rem;
    color: $color-text;
    -webkit-font-smoothing: antialiased;

    @media (min-width: $break_md_min) {

    }
}

* {
    box-sizing: border-box;
    outline: none;
}

h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}

img {
    display: block;
    max-width: 100%;
}

.offset-header {
    padding-top: rem(103);

    @media (max-width: $break_sm_max) {
        padding-top: rem(50);
    }
}

.container-custom {
    @include container();
    max-width: 1470px;
    height: 100%;
}

.title {
    margin-bottom: rem(85);
    font-weight: 700;
    font-size: rem(60);
    line-height: rem(73);
    color: $color-blue-secondary;
    text-transform: uppercase;
    letter-spacing: 0.015em;

    @media (max-width: $break_xs) {
        margin-bottom: rem(20);
        font-size: rem(22);
        line-height: rem(28);
    }

    @media (max-width: $break_sm_max) {
        margin-bottom: rem(20);
        font-size: rem(30);
        line-height: rem(36);
    }
}

.caption {
    margin-bottom: rem(15);
    font-weight: 700;
    font-size: rem(32);
    line-height: rem(38);
    text-transform: uppercase;
    color: $color-blue-secondary;

    @media (max-width: $break_sm_max) {
        margin-bottom: rem(40);
        font-size: rem(22);
        line-height: rem(24);
    }
}

.subtitle {
    margin-bottom: rem(50);
    font-size: rem(38);
    line-height: rem(46);
    color: $color-text;
    text-transform: uppercase;

    @media (max-width: $break_sm_max) {
        margin-bottom: rem(40);
        font-size: rem(20);
        line-height: rem(24);
    }
}

.par {
    font-size: rem(24);
    line-height: rem(30);

    b {
        font-weight: 700;
    }

    @media (max-width: $break_sm_max) {
        font-size: rem(18);
        line-height: rem(24);
    }
}

.link {
    font-weight: 600;
    color: $color-secondary;
    text-transform: uppercase;
    text-decoration: none;
    transition: $transition-animate;
    white-space: nowrap;

    &:hover {
        color: $color-text;
    }
}

.block {
    &_tile {
        box-shadow: 0px 10px 30px rgba(89, 71, 38, 0.08);
        border-radius: rem(37);
    }

    &_blue {
        background-color: $color-tile-blue;
    }

    &_green {
        background-color: $color-tile-green;
    }
}

.blockquote {
    position: relative;
    max-width: rem(1350);
    margin: 0;
    padding-top: rem(5);
    padding-bottom: rem(9);
    padding-left: rem(33);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: rem(10);
        height: 100%;
    }

    &_green {
        color: $color-green;

        &::before {
            background-color: $color-green;
        }
    }
    &_blue {
        color: $color-blue-secondary;

        &::before {
            background-color: $color-blue-main;
        }
    }

    .par {
        margin: 0;
        font-size: rem(32);
        line-height: rem(39);

        @media (max-width: $break_sm_max) {
            font-size: rem(18);
            line-height: rem(22);
        }

        b {
            text-transform: uppercase;
        }
    }
}

.bonus-17 {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-style: italic;
    font-size: rem(38);
    line-height: rem(40);
    color: $color-green;

    @media (max-width: $break_sm_max) {
        font-size: rem(22);
        line-height: rem(24);
        text-align: center;
    }

    &::before {
        content: "";
        width: rem(103);
        height: rem(103);
        margin-right: rem(13);
        background-color: $color-green;
        border-radius: 50%;

        @media (max-width: $break_sm_max) {
            width: rem(75);
            height: rem(75);
        }
    }

    &::after {
        content: "17";
        position: absolute;
        top: 0;
        left: rem(-2);
        width: rem(103);
        height: rem(103);
        font-weight: 700;
        font-size: rem(46);
        line-height: rem(103);
        color: $color-secondary;
        text-align: center;

        @media (max-width: $break_sm_max) {
            width: rem(75);
            height: rem(75);
            font-size: rem(30);
            line-height: rem(75);
        }
    }
}
