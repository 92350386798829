.component-action {
    position: relative;
    display: flex;
    height: rem(501);
    background: linear-gradient(119.22deg, #d8eaff 18.91%, #fbfdff 96.04%), #ffffff;
    justify-content: flex-end;

    @media (max-width: $break_md_max) {
        height: auto;
        flex-flow: column;
        background: $color-secondary;
    }

    .eyes-action {
        position: absolute;
        top: 0;
        left: 0;
        width: rem(837);
        height: 100%;
        min-height: 0;

        @media (max-width: $break_md_max) {
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            height: unset;
        }
    }

    .eyes-action__img {
        overflow: hidden;
        height: 100%;
        padding-top: 56.5%;
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 100%;

        @media (max-width: $break_md_max) {
            height: auto;
        }

        canvas {
            width: 100%;
            height: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            width: 100%;
            height: 100%;

            @media (max-width: $break_md_max) {
                max-width: 100%;
            }

            &.clean-desc {
                display: block;
                opacity: 0;
            }
        }

        .mud {
            opacity: 1;
        }

        .clean {
            opacity: 0;
        }
    }

    .eyes-action {

        &__component {
            &:hover {
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-animation-name: shake;
                animation-name: shake;
                -webkit-animation-duration: .8s;
                animation-duration: .8s;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
            }

            .mud {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                transition: 1s;
                opacity: 1;
                border-radius: 50%;
            }

            .clean {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                transition: 1s;
                opacity: 0;
                border-radius: 50%;
            }
        }

        &__component-1 {
            position: absolute;
            top: 5%;
            left: 1%;
            width: rem(130);
            height: rem(137);

            @media (max-width: $break_sm_max) {
                width: rem(52);
                height: rem(57);
            }
        }

        &__component-2 {
            position: absolute;
            bottom: 4%;
            left: 3%;
            width: rem(143);
            height: rem(141);

            @media (max-width: $break_sm_max) {
                width: rem(57);
                height: rem(56);
            }
        }

        &__component-3 {
            position: absolute;
            right: 5%;
            bottom: 4%;
            width: rem(133);
            height: rem(128);

            @media (max-width: $break_sm_max) {
                width: rem(54);
                height: rem(51);
            }
        }

        &.show-active {
            .eyes-action__component-1 {
                -webkit-animation: action-animate-ball-1 1s linear forwards;
                animation: action-animate-ball-1 1s linear forwards;
            }

            .eyes-action__component-2 {
                -webkit-animation: action-animate-ball-2 1s linear forwards;
                animation: action-animate-ball-2 1s linear forwards;
            }

            .eyes-action__component-3 {
                -webkit-animation: action-animate-ball-3 1s linear forwards;
                animation: action-animate-ball-3 1s linear forwards;
            }
        }

        &.snake {
            .eyes-action__component-1 {
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-animation: single-snake-1 1s linear forwards;
                animation: single-snake-1 1s linear forwards;
            }

            .eyes-action__component-2 {
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-animation: single-snake-2 1s linear forwards;
                animation: single-snake-2 1s linear forwards;
            }

            .eyes-action__component-3 {
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-animation: single-snake-3 1s linear forwards;
                animation: single-snake-3 1s linear forwards;
            }

            .mud {
                opacity: 0;
            }

            .clean {
                opacity: 1;
            }
        }

        &.show-active-back {
            .eyes-action__component-1 {
                -webkit-animation: action-animate-ball-back-1 2s linear forwards;
                animation: action-animate-ball-back-1 2s linear forwards;
            }

            .eyes-action__component-2 {
                -webkit-animation: action-animate-ball-back-2 2s linear forwards;
                animation: action-animate-ball-back-2 2s linear forwards;
            }

            .eyes-action__component-3 {
                -webkit-animation: action-animate-ball-back-3 2s linear forwards;
                animation: action-animate-ball-back-3 2s linear forwards;
            }
        }

    }
}

@-webkit-keyframes shake {
    0% {
        -webkit-transform: translate(2px, 1px) rotate(0deg);
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
        transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
        transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
        transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes shake {
    0% {
        -webkit-transform: translate(2px, 1px) rotate(0deg);
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
        transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
        transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
        transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@-webkit-keyframes action-animate-ball-1 {
    from {
        top: 5%;
        left: 1%;
    }
    to {
        top: 31%;
        left: 8%;
    }
}

@keyframes action-animate-ball-1 {
    from {
        top: 5%;
        left: 1%;
    }
    to {
        top: 31%;
        left: 8%;
    }
}

@-webkit-keyframes action-animate-ball-2 {
    from {
        bottom: 4%;
        left: 3%;
    }
    to {
        bottom: 25%;
        left: 28%;
    }
}

@keyframes action-animate-ball-2 {
    from {
        bottom: 4%;
        left: 3%;
    }
    to {
        bottom: 25%;
        left: 28%;
    }
}

@-webkit-keyframes action-animate-ball-3 {
    from {
        right: 5%;
        bottom: 4%;
    }
    to {
        right: 21%;
        bottom: 22%;
    }
}

@keyframes action-animate-ball-3 {
    from {
        right: 5%;
        bottom: 4%;
    }
    to {
        right: 21%;
        bottom: 22%;
    }
}

@-webkit-keyframes action-animate-ball-back-1 {
    from {
        top: 31%;
        left: 8%;
    }
    to {
        top: 5%;
        left: 1%;
    }
}

@keyframes action-animate-ball-back-1 {
    from {
        top: 31%;
        left: 8%;
    }
    to {
        top: 5%;
        left: 1%;
    }
}

@-webkit-keyframes action-animate-ball-back-2 {
    from {
        bottom: 25%;
        left: 28%;
    }
    to {
        bottom: 4%;
        left: 3%;
    }
}

@keyframes action-animate-ball-back-2 {
    from {
        bottom: 25%;
        left: 28%;
    }
    to {
        bottom: 4%;
        left: 3%;
    }
}

@-webkit-keyframes action-animate-ball-back-3 {
    from {
        right: 21%;
        bottom: 22%;
    }
    to {
        right: 5%;
        bottom: 4%;
    }
}

@keyframes action-animate-ball-back-3 {
    from {
        right: 21%;
        bottom: 22%;
    }
    to {
        right: 5%;
        bottom: 4%;
    }
}

@-webkit-keyframes single-snake-1 {
    0% {
        top: 31%;
        left: 8%;
        -webkit-transform: translate(2px, 1px) rotate(0deg);
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
        transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
        transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
        transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        top: 31%;
        left: 8%;
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes single-snake-1 {
    0% {
        top: 31%;
        left: 8%;
        -webkit-transform: translate(2px, 1px) rotate(0deg);
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
        transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
        transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
        transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        top: 31%;
        left: 8%;
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@-webkit-keyframes single-snake-2 {
    0% {
        bottom: 25%;
        left: 28%;
        -webkit-transform: translate(2px, 1px) rotate(0deg);
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
        transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
        transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
        transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        bottom: 25%;
        left: 28%;
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes single-snake-2 {
    0% {
        bottom: 25%;
        left: 28%;
        -webkit-transform: translate(2px, 1px) rotate(0deg);
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
        transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
        transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
        transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        bottom: 25%;
        left: 28%;
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@-webkit-keyframes single-snake-3 {
    0% {
        right: 21%;
        bottom: 22%;
        -webkit-transform: translate(2px, 1px) rotate(0deg);
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
        transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
        transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
        transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        right: 21%;
        bottom: 22%;
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes single-snake-3 {
    0% {
        right: 21%;
        bottom: 22%;
        -webkit-transform: translate(2px, 1px) rotate(0deg);
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
        transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
        transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
        transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        right: 21%;
        bottom: 22%;
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
        transform: translate(1px, -2px) rotate(-1deg);
    }
}
