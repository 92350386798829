.section-attention {
    padding-top: rem(80);
    padding-bottom: rem(60);
    background-color: $color-secondary;

    @media (max-width: $break_sm_max) {
        padding-top: rem(32);
        padding-bottom: rem(32);
    }

    figure {
        margin: 0;
        font-size: 0;
    }
}

.footer {
    padding-top: rem(65);
    padding-bottom: rem(93);
    background: linear-gradient(90deg, #2e3180 0%, #3e9bd4 95%);

    @media (max-width: $break_md_max) {
        padding-bottom: rem(65);
    }

    &__row {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto;
        row-gap: rem(56);

        @media (max-width: $break_md_max) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 0;
        }

        &_first {
            padding-bottom: rem(80);
            border-bottom: rem(2) solid rgb(255 255 255 / 50%);

            @media (max-width: $break_md_max) {
                padding-bottom: rem(50);
            }

            .footer {
                &__brand {
                    grid-row-start: 1;
                    grid-column-start: 1;
                    grid-row-end: 2;
                    grid-column-end: 2;
                    align-self: end;

                    @media (max-width: $break_md_max) {
                        align-self: start;
                        margin-bottom: rem(40);
                    }

                    img {
                        width: rem(235);
                        height: rem(46);
                    }
                }

                &__copyright {
                    grid-row-start: 2;
                    grid-column-start: 1;
                    grid-row-end: 3;
                    grid-column-end: 2;
                    align-self: end;

                    @media (max-width: $break_md_max) {
                        align-self: start;
                        margin-bottom: rem(40);
                    }

                    p {
                        margin: 0;
                        font-weight: 400;
                        font-size: rem(16);
                        line-height: rem(23);
                        color: $color-secondary;
                    }
                }

                &__caption {
                    grid-row-start: 1;
                    grid-column-start: 2;
                    grid-row-end: 2;
                    grid-column-end: 3;
                    align-self: end;

                    @media (max-width: $break_md_max) {
                        align-self: start;
                        margin-bottom: rem(20);
                    }

                    h6 {
                        font-weight: 700;
                        font-size: rem(20);
                        line-height: rem(24);
                        color: $color-secondary;
                        text-transform: uppercase;
                    }
                }

                &__nav {
                    grid-row-start: 2;
                    grid-column-start: 2;
                    grid-row-end: 3;
                    grid-column-end: 4;
                    align-self: end;

                    @media (max-width: $break_md_max) {
                        align-self: start;
                        margin-bottom: rem(40);
                    }

                    ul {
                        display: flex;
                        flex-direction: row;
                        padding-left: 0;
                        list-style: none;

                        @media (max-width: $break_md_max) {
                            flex-direction: column;
                        }

                        li {
                            margin-right: rem(64);
                            line-height: rem(23);
                            font-size: 0;
                            color: $color-secondary;

                            @media (max-width: $break_md_max) {
                                margin-bottom: 10px;
                            }

                            &:last-child {
                                margin-right: 0;

                                @media (max-width: $break_md_max) {
                                    margin-bottom: 0;
                                }
                            }

                            a {
                                display: block;
                                font-weight: 700;
                                font-size: rem(19);
                                line-height: rem(23);

                                @media (max-width: $break_xs) {
                                    white-space: normal;
                                }
                            }

                            span {
                                font-size: rem(16);
                                line-height: rem(20);
                            }
                        }
                    }
                }

                &__scroll-up {
                    grid-row-start: 1;
                    grid-column-start: 4;
                    grid-row-end: 2;
                    grid-column-end: 5;
                    align-self: end;
                    justify-self: end;

                    @media (max-width: $break_md_max) {
                        align-self: start;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        position: relative;
                        padding: rem(7);
                        padding-right: rem(34);
                        font-weight: 700;
                        font-size: rem(12);
                        line-height: rem(15);
                        text-align: left;
                        cursor: pointer;

                        &::before, &::after {
                            content: "";
                            width: rem(43);
                            height: rem(43);
                            margin-right: rem(11);
                            background-color: $color-secondary;
                            background-repeat: no-repeat;
                            background-position: center;
                            border-radius: 50%;
                            font-size: 0;
                            transition: $transition-animate;
                        }

                        &::before {
                            background-image: url("../../../img/arrow-scroll-up.svg");
                        }

                        &::after {
                            position: absolute;
                            top: rem(7);
                            left: rem(7);
                            opacity: 0;
                            background-color: $color-blue-secondary;
                            background-image: url("../../../img/arrow-scroll-up.hover.svg");
                        }

                        &:hover {
                            &::before {
                                opacity: 0;
                            }

                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &_second {
            padding-top: rem(40);

            @media (max-width: $break_md_max) {
                padding-top: rem(50);
            }

            .footer {
                &__brand {
                    grid-row-start: 1;
                    grid-column-start: 1;
                    grid-row-end: 2;
                    grid-column-end: 2;
                    align-self: center;

                    @media (max-width: $break_md_max) {
                        align-self: start;
                    }

                    img {
                        width: rem(152);
                        height: rem(96);
                    }
                }

                &__address {
                    grid-row-start: 1;
                    grid-column-start: 2;
                    grid-row-end: 2;
                    grid-column-end: 3;
                    align-self: center;
                    font-size: 0;

                    @media (max-width: $break_md_max) {
                        align-self: start;
                        margin-bottom: rem(40);
                    }

                    span {
                        font-style: normal;
                        font-size: rem(16);
                        line-height: rem(20);
                        color: $color-secondary;
                    }
                }

                &__contacts {
                    display: flex;
                    flex-direction: column;
                    align-self: center;
                    grid-row-start: 1;
                    grid-column-start: 3;
                    grid-row-end: 2;
                    grid-column-end: 4;
                    line-height: rem(20);

                    @media (max-width: $break_md_max) {
                        align-self: start;
                        margin-bottom: rem(40);
                    }

                    a {
                        font-size: rem(16);
                        color: $color-secondary;
                        text-decoration: none;
                    }
                }

                &__soc {
                    display: inline-flex;
                    align-items: center;
                    align-self: center;
                    grid-row-start: 1;
                    grid-column-start: 4;
                    grid-row-end: 2;
                    grid-column-end: 4;

                    @media (max-width: $break_md_max) {
                        align-self: start;
                    }

                    &__link {
                        background-size: cover;
                        font-size: 0;

                        &_vk {
                            width: rem(29);
                            height: rem(29);
                            margin-right: rem(10);
                            background-image: url("../../../img/soc.vk.svg");

                            @media (max-width: $break_md_max) {
                                margin-right: rem(20);
                            }
                        }

                        &_ok {
                            width: rem(140);
                            height: rem(27);
                            background-image: url("../../../img/soc.odnoklassniki.png");
                        }
                    }
                }

                &__nav {
                    grid-row-start: 2;
                    grid-column-start: 2;
                    grid-row-end: 3;
                    grid-column-end: 4;

                    @media (max-width: $break_md_max) {
                        margin-bottom: rem(40);
                    }

                    ul {
                        padding-left: 0;
                        list-style: none;

                        li {
                            font-size: 0;

                            @media (max-width: $break_md_max) {
                                margin-bottom: rem(10);
                            }

                            a {
                                font-weight: 700;
                                font-size: rem(19);
                                line-height: rem(30);

                                @media (max-width: $break_md_max) {
                                    font-size: rem(16);
                                    line-height: rem(26);
                                    white-space: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
