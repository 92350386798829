/* colors */
$color-primary: #000000;
$color-secondary: #FFFFFF;
$color-blue-main: #0093c9;
$color-blue-secondary: #2C4A9D;
$color-green: #6CA735;
$color-text: #0C284E;
$color-slider-section: #F5F5F5;
$color-tile-blue: #EDF8FF;
$color-tile-green: #F6F9E4;


$box-shadow-blocks: 0 10px 30px rgba(89, 71, 38, 0.08);
$transition-animate: all 0.3s linear;




