.pharmacy-buy {
    &__link {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: $color-secondary;
        box-shadow: 0px 10px 30px rgba(89, 71, 38, 0.08);
        border-radius: rem(30);
        text-decoration: none;
    }

    &__img {
        max-width: 40%;
    }

    &__discount {
        position: absolute;
        top: rem(-23);
        right: rem(-59);
        width: rem(166);
        height: rem(92);
        padding-top: rem(48);
        background: #FF7575;
        text-align: center;
        transform: matrix(0.71, 0.76, -0.66, 0.71, 0, 0);

        span {
            display: block;
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(17);
            color: $color-secondary;
            text-align: center;
            text-transform: uppercase;
        }
    }
}
