.page-main {
    .section {
        &-hero {
            max-width: rem(1920);
            height: rem(960);
            margin: 0 auto;
            background: url("../../img/pages/main/section.one.bg.png") top right no-repeat;
            background-size: cover;

            @media (max-width: $break_xs) {
                height: 230px;
            }

            @media (max-width: $break_sm_max) {
                height: 300px;
                background-position: right 20px;
            }

            @media (max-width: $break_md_max) {
                height: rem(800);
            }

            &__title {
                padding-top: rem(7);

                @media (max-width: $break_sm_max) {
                    padding-top: rem(80);
                }

                @media (min-width: $break_sm_min) {
                    padding-top: rem(173);
                }

                @media (min-width: $break_md_min) {
                    padding-top: rem(180);
                }

                img {
                    width: rem(636);
                    height: rem(375);


                    @media (max-width: $break_xs) {
                        width: 200px;
                    }

                    @media (max-width: $break_sm_max) {
                        width: 300px;
                        height: 100%;
                    }
                }
            }
        }

        &-products {

            @media (max-width: $break_sm_max) {
                padding-top: 20px;
            }

            @media (min-width: $break_md_min) {
                margin-top: rem(-290);
            }

            @media (min-width: $break_sm_min) {
                margin-top: rem(-180);
            }

            &__list {
                display: flex;
                justify-content: space-between;

                @media (max-width: $break_sm_max) {
                    flex-direction: column;
                    align-items: center;
                }

                .list__item {
                    width: 100%;
                    height: rem(310);
                    position: relative;
                    margin-right: rem(30);
                    box-shadow: $box-shadow-blocks;
                    border-radius: rem(23);
                    background: #FFFFFF;

                    @media (max-width: $break_xs) {
                        overflow: hidden;
                    }

                    @media (max-width: $break_sm_max) {
                        max-width: 470px;
                        margin-right: 0;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    &_lightblue {

                        &::before {
                            right: rem(17);
                            bottom: rem(12);
                            width: rem(181);
                            height: rem(303);
                            background-image: url("../../img/pages/main/section.two.lightblue.png");

                            @media (max-width: $break_xs) {
                                right: rem(-10);
                                bottom: rem(-20);
                                width: 45%;
                            }
                        }

                        .caption {
                            background-color: $color-blue-main;
                        }

                        li {
                            &::before {
                                background-image: url("../../img/pages/main/section.two.effect.lightblue.svg");
                            }
                        }
                    }

                    &_blue {
                        &::before {
                            right: 0;
                            bottom: rem(10);
                            width: rem(225);
                            height: rem(274);
                            background-image: url("../../img/pages/main/section.two.blue.png");

                            @media (max-width: $break_xs) {
                                right: rem(-10);
                                bottom: rem(-20);
                                width: 45%;
                            }
                        }

                        .caption {
                            background-color: $color-blue-secondary;
                        }

                        li {
                            &::before {
                                background-image: url("../../img/pages/main/section.two.effect.blue.svg");
                            }
                        }

                        .bonus {
                            margin-top: rem(18);
                            color: $color-blue-secondary;

                            &::before {
                                background-image: url("../../img/pages/main/section.two.blue.bonus.svg");
                                background-size: contain;
                                background-repeat: no-repeat;
                            }
                        }
                    }

                    &_green {
                        &::before {
                            right: rem(7);
                            bottom: rem(41);
                            width: rem(241);
                            height: rem(201);
                            background-image: url("../../img/pages/main/section.two.green.png");

                            @media (max-width: $break_xs) {
                                right: 0;
                                bottom: rem(20);
                                width: 50%;
                            }
                        }

                        .caption {
                            background-color: $color-green;
                        }

                        li {
                            &::before {
                                background-image: url("../../img/pages/main/section.two.effect.green.svg");
                            }
                        }

                        .bonus {
                            position: relative;
                            color: $color-green;

                            &::before {
                                background-color: $color-green;
                                border-radius: 50%;
                            }

                            &::after {
                                content: "17";
                                position: absolute;
                                top: 0;
                                left: rem(-2);
                                width: rem(50);
                                font-weight: 700;
                                font-size: rem(26);
                                line-height: rem(50);
                                color: $color-secondary;
                                text-align: center;
                            }
                        }
                    }

                    .caption {
                        margin-bottom: 0;
                        padding: rem(16) 0 rem(7) rem(27);
                        border-radius: rem(23) rem(23) 0 0;
                        font-weight: 600;
                        font-size: rem(22);
                        line-height: rem(27);
                        color: $color-secondary;
                        text-transform: none;

                        @media (max-width: $break_xs) {
                            margin-bottom: 0;
                            padding-left: rem(15);
                            font-size: rem(20);
                            line-height: rem(15);
                        }
                    }

                    .effects {
                        list-style: none;
                        margin-top: rem(22);
                        padding-left: rem(27);

                        @media (max-width: $break_xs) {
                            padding-left: 15px;
                        }

                        li {
                            display: flex;
                            align-items: flex-start;
                            font-size: rem(17);
                            line-height: rem(21);

                            @media (max-width: $break_xs) {
                                font-size: 12px;
                                line-height: 16px;
                            }

                            &::before {
                                content: "";
                                width: rem(23);
                                height: rem(23);
                                margin-right: rem(8);
                                background-size: cover;

                                @media (max-width: $break_xs) {
                                    width: rem(15);
                                    height: rem(15);
                                }
                            }

                            & + li {
                                margin-top: rem(7);
                            }
                        }
                    }

                    .bonus {
                        position: absolute;
                        bottom: rem(30);
                        left: rem(27);
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        font-style: italic;
                        font-size: rem(17);
                        line-height: rem(21);
                        letter-spacing: -0.02em;

                        &::before {
                            content: "";
                            width: rem(50);
                            height: rem(50);
                            margin-right: rem(8);
                        }
                    }
                }
            }
        }

        &-to-help {
            padding-top: rem(80);
            padding-bottom: rem(154);
            background-color: $color-secondary;


            @media (max-width: $break_sm_max) {
                padding-top: rem(40);
                padding-bottom: rem(40);
            }

            @media (min-width: $break_md_min) {
                padding-top: rem(124);
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                @media (max-width: $break_sm_max) {
                    flex-direction: column;
                    align-items: center;
                }

                .list__item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    width: calc((100% / 3) - rem(20));
                    margin-top: rem(180);
                    margin-right: rem(30);
                    padding: 0 rem(15) rem(25);
                    box-shadow: $box-shadow-blocks;
                    border-radius: rem(23);
                    text-align: center;
                    transition: $transition-animate;

                    @media (max-width: $break_sm_max) {
                        width: 100%;
                        margin-right: 0;

                        &:first-child {
                            margin-top: rem(140);
                        }
                    }

                    @media (max-width: $break_md_max) {
                        padding-bottom: rem(100);
                    }

                    &:hover {
                        .par {
                            opacity: 0;

                            @media (max-width: $break_md_max) {
                                opacity: 1;
                            }
                        }

                        .list__item__btn {
                            opacity: 1;
                        }
                    }

                    &:nth-child(3n+3) {
                        margin-right: 0;
                    }

                    &:nth-child(even) {
                        background-color: #F6F9E4;

                        &::before {
                            background: linear-gradient(90deg, #CDD421 0%, #7AAB35 95%);
                            opacity: 0.4;
                        }

                        .caption {
                            color: $color-green;
                        }

                        .list__item__btn {
                            background-color: $color-green;
                            border-color: $color-green;

                            &:hover {
                                background-color: $color-secondary;
                                color: $color-green;
                            }
                        }
                    }

                    &:nth-child(odd) {
                        background-color: #EDF8FF;

                        &::before {
                            background: linear-gradient(90deg, #2E3180 0%, #3E9BD4 95%);
                            opacity: 0.4;
                        }

                        .caption {
                            color: $color-blue-main;
                        }

                        .list__item__btn {
                            background-color: $color-blue-secondary;
                            border-color: $color-blue-secondary;

                            &:hover {
                                background-color: $color-secondary;
                                color: $color-blue-secondary;
                            }
                        }
                    }

                    &::before {
                        content: "";
                        width: rem(210);
                        height: rem(210);
                        margin-top: rem(-105);
                        border-radius: 50%;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: rem(-99);
                        left: 50%;
                        width: rem(198);
                        height: rem(198);
                        transform: translateX(-50%);
                        background-size: contain;
                        border-radius: 50%;
                    }

                    &:nth-child(1) {
                        &::after {
                            background-image: url("../../img/pages/main/section.three.first.png");
                        }
                    }

                    &:nth-child(2) {
                        &::after {
                            background-image: url("../../img/pages/main/section.three.second.png");
                        }
                    }

                    &:nth-child(3) {
                        &::after {
                            background-image: url("../../img/pages/main/section.three.third.png");
                        }
                    }

                    &:nth-child(4) {
                        &::after {
                            background-image: url("../../img/pages/main/section.three.fourth.png");
                        }
                    }

                    &:nth-child(5) {
                        &::after {
                            background-image: url("../../img/pages/main/section.three.fifth.png");
                        }
                    }


                    .caption {
                        position: relative;
                        margin-bottom: 0;
                        padding-top: rem(20);
                        font-weight: 700;
                        font-size: rem(36);
                        line-height: 110%;
                        text-align: center;
                        text-transform: none;

                        @media (max-width: $break_sm_max) {
                            margin-bottom: 0;
                            font-size: rem(24);
                        }
                    }

                    .par {
                        margin-top: rem(30);
                        transition: $transition-animate;
                    }

                    &__btn {
                        opacity: 0;
                        position: absolute;
                        bottom: rem(90);
                        font-weight: 600;
                        font-size: rem(24);
                        line-height: rem(30);

                        @media (max-width: $break_xs) {
                            padding-top: rem(10);
                            padding-bottom: rem(10);
                            font-size: rem(20);
                        }

                        @media (max-width: $break_md_max) {
                            opacity: 1;
                            bottom: rem(30);
                        }
                    }

                }
            }
        }

        &-pharmacy-buy {
            padding-top: rem(115);
            padding-bottom: rem(145);
            background-color: $color-slider-section;

            @media (max-width: $break_sm_max) {
                padding-top: rem(70);
                padding-bottom: rem(75);
            }

            .layout {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &__slider {
                position: relative;
                margin-top: rem(75);

                @media (max-width: $break_xs) {
                    margin-top: rem(40);
                }

                .slick-container {
                    width: calc(100% - 7.5rem);
                    margin: 0 auto;

                    @media (max-width: $break_sm_max) {
                        width: 100%;
                    }

                    .slick-list {
                        padding-bottom: 30px;
                    }

                    .slick-arrow {
                        margin-top: rem(-38);

                        @media (max-width: $break_sm_max) {
                            top: 0;
                            margin-top: rem(-93);
                        }
                    }

                    .slick-dots {
                        margin-top: rem(32);

                        @media (max-width: $break_xs) {
                            margin-top: rem(16);
                        }
                    }
                }

                .slider {
                    &__item {
                        height: rem(185);
                        margin: 0 rem(11);
                    }

                    &__pagination {
                        .swiper-pagination {
                            position: relative;
                            bottom: auto;
                            display: flex;
                            flex-direction: row;
                        }
                    }
                }
            }

            &__btn {
                margin-top: rem(43);
                margin-bottom: rem(80);
                font-size: rem(19);
                line-height: rem(23);
                text-transform: uppercase;
                white-space: nowrap;

                @media (max-width: $break_xs) {
                    margin-bottom: rem(30);
                    font-size: rem(15);
                }
            }

            &__par {
                margin: 0;
                font-size: rem(16);
                line-height: rem(24);
            }
        }
    }
}
