.section-article-prev {

    @media (max-width: $break_md_max) {
        padding-top: rem(80);
        padding-bottom: rem(80);
    }

    @media (min-width: $break_md_min) {
        padding-top: rem(120);
        padding-bottom: rem(150);
    }

    .content {
        display: flex;

        @media (max-width: $break_sm_max) {
            flex-direction: column;
        }

        @media (min-width: $break_sm_min) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .article-prev {
        &__item {
            max-width: rem(471);
            padding: rem(38) rem(18) 0;

            @media (min-width: $break_sm_min) {
                width: rem(471);
            }

            a {
                text-decoration: none;
            }

            &_product {
                background-image: url("../../img/artcile.bg.jpg");
                background-repeat: no-repeat;
                background-size: contain;

                .caption {
                    color: $color-secondary;
                    text-transform: uppercase;
                }

                .article-prev__tag {
                    color: rgba(255, 255, 255, 0.7);
                }
            }
        }

        &__content {
            padding: 0 rem(21) rem(20);
            font-size: 0;

            .caption {
                margin-top: rem(10);
                margin-bottom: rem(10);
                font-size: rem(26);
                line-height: 105.1%;
                text-transform: none;
            }
            .par {
                margin: 0;
                font-weight: 500;
                font-size: rem(18);
                line-height: rem(18);
                color: $color-text;
            }
        }

        &__tag {
            font-size: rem(16);
            line-height: rem(20);
            color: rgba(41, 82, 174, 0.7);
            text-decoration: underline;
        }
    }
}
