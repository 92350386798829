.page-pharmacy {
    .section {
        &-details {
            padding-bottom: rem(60);

            .actions {
                display: flex;
                font-size: 0;

                @media (max-width: $break_sm_max) {
                    flex-direction: column;
                    margin-bottom: rem(40);
                }

                @media (min-width: $break_sm_min) {
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: rem(140);
                }

                .pharmacy__btn_uteka {
                    filter: drop-shadow(0px 10px 30px rgba(89, 71, 38, 0.08));

                    @media (max-width: $break_sm_max) {
                        margin-bottom: rem(40);
                    }

                    @media (min-width: $break_sm_min) {
                        padding-top: rem(9);
                        padding-bottom: rem(9);
                        font-size: rem(24);
                    }
                }
            }

            .pharmacies {

                &__list {
                    &[aria-show="false"] {
                        height: 0;
                        overflow: hidden;
                    }

                    >div {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-start;

                        @media (max-width: $break_sm_max) {
                            justify-content: center;
                        }
                    }
                }

                &__link {
                    flex-direction: column;

                    @media (max-width: $break_xs) {
                        height: rem(180);
                    }

                    @media (max-width: $break_sm_max) {
                        width: auto;
                        height: rem(220);
                    }

                }

                &__item {
                    height: rem(240);
                    font-size: 0;

                    @media (max-width: $break_xs) {
                        margin: 0;
                        margin-right: rem(10);
                        padding-top: rem(35);
                    }

                    @media (max-width: $break_sm_max) {
                        margin: 0 rem(11);
                    }

                    @media (min-width: $break_sm_min) {
                        width: calc((100% / 4) - rem(26));
                        margin-right: rem(33);
                        margin-bottom: rem(58);

                        &:nth-child(4n+4) {
                            margin-right: 0;
                        }
                    }

                }

                &__img-wrap {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: rem(250);
                    padding: rem(20) rem(15);

                    @media (max-width: $break_xs) {
                        margin-bottom: rem(10);
                        padding: rem(30) rem(10) rem(5);
                    }

                    img {
                        max-width: 50%;
                        max-height: rem(230);
                    }
                }

                &__content {
                    text-align: center;
                    color: $color-blue-secondary;

                    span {
                        display: block;
                        font-weight: 600;
                        line-height: 102.4%;

                        @media (max-width: $break_sm_max) {
                            font-size: rem(10);
                        }

                        @media (min-width: $break_sm_min) {
                            font-size: rem(12);
                        }
                    }

                    h4 {
                        margin-top: rem(5);
                        margin-bottom: rem(30);
                        font-weight: 700;
                        line-height: 102.4%;

                        @media (max-width: $break_xs) {
                            margin-bottom: rem(20);
                            font-size: rem(14);
                        }

                        @media (max-width: $break_sm_max) {
                            font-size: rem(18);
                        }

                        @media (min-width: $break_sm_min) {
                            font-size: rem(24);
                        }
                    }
                }

                &__discount {

                    @media (max-width: $break_xs) {
                        top: 0;
                        right: 0;
                        transform: none;
                        width: 100%;
                        height: rem(35);
                        padding-top: 0;
                    }

                    span {

                        @media (max-width: $break_xs) {
                            font-size: rem(12);
                        }
                    }
                }

            }
        }
    }
}
